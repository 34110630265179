import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SelectionService } from '../../core/selection/service/selection.service';
import { IProfileDisplayDataContext } from '../../search/interfaces.defs';
import { ESelectionName } from '../../core/selection/interfaces.defs';
import { EPageNames } from '../../interfaces.defs';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../shared/notification/notification.service';
import { ProfileService } from '../../profile/service/profile.service';
import { AttributeService } from '../../profile/service/attribute.service';
import { filter, take } from 'rxjs/operators';
import { VisibilityService } from "../../core/visibility/service/visibility.service";

@Component( {
  selector: 'app-profile-view-attribute',
  templateUrl: './profile-view-attribute.component.html',
  styleUrls: [ '../action-bar.components.scss' ]
} )
export class ProfileViewAttributeComponent implements OnInit
{
  private rootRoute: ActivatedRoute;
  private uuid: string;

  constructor(
    private selectionService: SelectionService,
    private router: Router,
    private notificationService: NotificationService,
    private attributeService: AttributeService,
    public location: Location,
    public profileService: ProfileService,
    public visibilityService: VisibilityService
  ) {}

  ngOnInit()
  {
    this.rootRoute = this.router.routerState.root.children[0];

    const primaryRoute: ActivatedRoute = this.rootRoute.children.filter( ( route: ActivatedRoute ) => route.outlet === 'primary' )[0];

    this.uuid = primaryRoute.snapshot.paramMap.get( 'uuid' );
  }

  editAttribute()
  {
    const attributeSelection = this.selectionService.getSelectionCollection<IProfileDisplayDataContext>( ESelectionName.CUSTOMER_DISPLAY_DATA_SELECTION ),
      currentAttribute = attributeSelection.selected[ 0 ]
    ;

    this.router.navigate( [ EPageNames.PROFILE_EDIT_ATTRIBUTE, this.uuid, currentAttribute.contextId ], { relativeTo: this.rootRoute } );
  }

  deleteAttribute()
  {
    const addressSelection = this.selectionService.getSelectionCollection<IProfileDisplayDataContext>( ESelectionName.CUSTOMER_DISPLAY_DATA_SELECTION ),
      currentAttribute = addressSelection.selected[ 0 ]
    ;

    this.attributeService.delete( this.uuid, currentAttribute.contextId )
      .pipe(
        take( 1 ),
        filter( attribute => attribute === null)
      )
      .subscribe( () => {
        this.notificationService.showSuccessMessage( 'Attribute successfully deleted.' );
        this.profileService.showProfileButtons();
        this.profileService.refresh( this.uuid );
      } );
  }
}
