import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { IProfileActionButton, IScopeConfigurationDisplay, IScopeConfigurationResponse } from "../interfaces.defs";
import { API_ENDPOINT_MAP, ApiService } from "../../api/api.service";
import { map } from "rxjs/operators";
import { IProfileDisplayData } from "../../../search/interfaces.defs";

@Injectable({
  providedIn: 'root'
})
export class ScopeConfigurationService {

  private _currentScope: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _currentScopeConfiguration: Observable<IScopeConfigurationDisplay>;

  private _currentData: BehaviorSubject<IProfileDisplayData> = new BehaviorSubject<IProfileDisplayData>(null);

  constructor( private apiService: ApiService ) {
    this._currentScope.subscribe( ( scope: string) => {
      this._currentScopeConfiguration = this.apiService.get<IScopeConfigurationResponse>(API_ENDPOINT_MAP.scopeConfiguration.get, {scope: scope})
        .pipe(
          map( (scopeConfigurationResponse: IScopeConfigurationResponse ) => {
            if ( scopeConfigurationResponse && scopeConfigurationResponse.scopeConfiguration[0] ) {
              return scopeConfigurationResponse.scopeConfiguration[0];
            }
            return null;
          } )
        );
    });
  }

  public reset(): void {
    this._currentScope.next( '' );
    this._currentData.next( null );
  }

  public determineButtonName( profileActionButton: IProfileActionButton ): string {
    if ( profileActionButton ) {
      return profileActionButton.name;
    }
    return '';
  }

  public performButtonAction(profileActionButton: IProfileActionButton ) {
    let data = this._currentData.getValue();
    if ( data ) {
      let userData = data.user;
      let memberData = data.member;
      let parsedUrl = profileActionButton.link;

      if ( userData ) {
        parsedUrl = this.replaceAttributes(parsedUrl, userData);
      }

      if ( memberData ) {
        parsedUrl = this.replaceAttributes(parsedUrl, memberData);
      }

      if ( profileActionButton.newTab ) {
        window.open(parsedUrl, "_blank");
      } else {
        window.location.href = parsedUrl;
      }
    }
  }

  private replaceAttributes(url: string, data: any): string {
    Object.keys( data ).forEach( ( attribute ) => {
      url = url.replace( `:${attribute}`, encodeURIComponent( data[attribute].toString() ) );
    } );
    return url;
  }

  get currentScopeConfiguration(): Observable<IScopeConfigurationDisplay> {
    return this._currentScopeConfiguration;
  }

  set currentScope(value: string) {
    this._currentScope.next( value );
  }

  set currentData(value: IProfileDisplayData) {
    this._currentData.next( value );
  }
}
