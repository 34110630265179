import { Injectable } from '@angular/core';
import {
  IContractApiResponse,
  IProfileDisplayDataContract,
  IProfileDisplayDataContractDefinition,
} from '../../search/interfaces.defs';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { filter, switchMap, take } from 'rxjs/operators';
import { API_ENDPOINT_MAP, ApiService } from '../../core/api/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ContractDeleteConfirmationDialogComponent } from '../delete/contract-delete-confirmation-dialog/contract-delete-confirmation-dialog.component';
import { ContractDefinitionService } from './contract-definition.service';
import { formatDate } from '@angular/common';
import { DATE_FORMATS } from '../../shared/date-picker/date-picker.module';
import { AbstractFormService } from '../../core/form/abstract-form-service';

@Injectable( {
  providedIn: 'root'
} )
export class ContractService extends AbstractFormService
{
  private _contract: BehaviorSubject<IProfileDisplayDataContract> = new BehaviorSubject<IProfileDisplayDataContract>( null );

  constructor(
    private apiService: ApiService,
    private contractDefinitionService: ContractDefinitionService,
    private dialog: MatDialog
  )
  {
    super();
  }

  public get form(): FormGroup
  {
    return this._form;
  }

  public set form( form: FormGroup )
  {
    /**
     * form cannot be set from outside
     */
  }

  public get contract(): IProfileDisplayDataContract
  {
    return this._contract.getValue();
  }

  public get( uuid: string ): Observable<IProfileDisplayDataContract>
  {
    return this.apiService.get<IContractApiResponse>( API_ENDPOINT_MAP.contract.get, { uuid: uuid } )
      .pipe(
        switchMap( ( result: IContractApiResponse ) =>
        {
          return this.contractDefinitionService.search( uuid, { uuid: result.contract.contractDefinitionUuid } )
            .pipe(
              take( 1 ),
              switchMap( ( contractDefinitions: IProfileDisplayDataContractDefinition[] ) =>
              {
                result.contract.definition = contractDefinitions[0];

                this._contract.next( result.contract );

                return this._contract;
              } )
            );
        } )
      );
  }

  public create( uuid: string ): Observable<IProfileDisplayDataContract>
  {
    this.triggerFormValidation( this._form );

    if ( !this._form.valid ) {
      return of( null );
    }

    const additionalAttributesGroup = <FormGroup>this._form.get( 'additionalAttributes' ),
      data = {
        contract: {
          status: this._form.get( 'status' ).value,
          expirationDate: this._form.get( 'expirationDate' ).value
            ? formatDate( this._form.get( 'expirationDate' ).value, DATE_FORMATS.parse.dateInput, 'en-US' )
            : this._form.get( 'expirationDate' ).value,
          contractDefinitionUuid: this._form.get( 'type' ).value,
          userUuid: uuid,
          additionalAttributes: Object.keys( additionalAttributesGroup.controls ).map( attributeId =>
          {
            const attributeValue = additionalAttributesGroup.get( attributeId ).value;

            if ( !attributeValue ) {
              return null;
            }

            return {
              id: attributeId,
              value: attributeValue
            };
          } ).filter( value => value )
        }
      };

    return this.apiService.post<IContractApiResponse>( API_ENDPOINT_MAP.contract.post, data, { uuid: uuid }, undefined, this.errorHandler.bind(this) )
      .pipe(
        switchMap( ( data: IContractApiResponse ) =>
        {
          const contract = data.contract,
            contractDefinition = this.contractDefinitionService.definitions.find( ( definition: IProfileDisplayDataContractDefinition ) =>
            {
              return definition.uuid = contract.contractDefinitionUuid;
            } );

          this._form.markAsPristine();

          contract.definition = contractDefinition;

          this._contract.next( contract );

          return this._contract;
        } )
      );
  }

  public update( uuid: string ): Observable<IProfileDisplayDataContract>
  {
    this.triggerFormValidation( this._form );

    if ( !this._form.valid ) {
      return of( null );
    }

    const additionalAttributesGroup = <FormGroup>this._form.get( 'additionalAttributes' ),
      data = {
        comment: this._form.get( 'comment' ).value,
        contractInstance: {
          status: this._form.get( 'status' ).value,
          expirationDate: this._form.get( 'expirationDate' ).value
            ? formatDate( this._form.get( 'expirationDate' ).value, DATE_FORMATS.parse.dateInput, 'en-US' )
            : null,
          contractDefinitionUuid: this._form.get( 'type' ).value,
          userUuid: this._form.get( 'userUuid' ).value,
          uuid: this._form.get( 'uuid' ).value,
          additionalAttributes: Object.keys( additionalAttributesGroup.value ).map( attributeId =>
          {
            const attributeValue = additionalAttributesGroup.get( attributeId ).value;
            return {
              id: attributeId,
              value: attributeValue ? attributeValue : null
            };
          } ).filter( value => value )
        }
      };

    return this.apiService.put<IContractApiResponse>( API_ENDPOINT_MAP.contract.put, { contract: data }, { uuid: uuid }, undefined )
      .pipe(
        switchMap( ( data: IContractApiResponse ) =>
        {

          const contract = data.contract,
            contractDefinition = this.contractDefinitionService.definitions.find( ( definition: IProfileDisplayDataContractDefinition ) =>
            {
              return definition.uuid = contract.contractDefinitionUuid;
            } );

          this._form.markAsPristine();

          contract.definition = contractDefinition;

          this._contract.next( contract );

          return this._contract;
        } )
      );
  }

  public delete( uuid: string ): Observable<IProfileDisplayDataContract>
  {
    const confirmedAction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false ),
      dialogRef = this.dialog.open( ContractDeleteConfirmationDialogComponent )
    ;

    dialogRef.afterClosed()
      .subscribe( confirmation =>
      {
        confirmedAction.next( confirmation );
        confirmedAction.complete();
      } );

    return confirmedAction
      .pipe(
        filter( confirmation => confirmation ),
        switchMap( () =>
        {
          return this.apiService.delete<IContractApiResponse>(
            API_ENDPOINT_MAP.contract.delete,
            { uuid: uuid }
          )
            .pipe(
              switchMap( ( result: IContractApiResponse ) =>
              {
                this._contract.next( null );
                return this._contract;
              } )
            );
        } )
      );
  }
}
