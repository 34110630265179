import { NgModule, Injectable } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { formatDate } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter, NativeDateModule } from '@angular/material/core';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  public format( date: Date, displayFormat: Object ): string
  {
    switch ( displayFormat ) {
      case 'yyyy-MM-dd':
      case 'MMM yyyy':
        return formatDate( date, <string>displayFormat, 'en-US' );
      default:
        return super.format( date, displayFormat );
    }
  }

  public getFirstDayOfWeek(): number {
    return 1;
  }
}

export const DATE_FORMATS = {
  parse: {
    dateInput: 'yyyy-MM-dd',
  },
  display: {
    dateInput: 'yyyy-MM-dd',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'yyyy-MM-dd',
    monthYearA11yLabel: 'MMM yyyy',
  },
};

@NgModule({
  exports: [
    MatDatepickerModule,
    NativeDateModule
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ]
})
export class DatePickerModule { }
