import { FormGroup } from '@angular/forms';

export interface IFormControlConfigurationCollection
{
  [key: string]: IFormControlConfiguration;
}

export interface IFormControlConfiguration
{
  format: EFormControlConfigurationAllowedFormat;
  label: string;
  pattern?: string;
  required?: boolean;
  disabled?: boolean;
  allowedValues?: string[];
  displayValues?: string[];
  value?: string | number | boolean | IFormControlConfigurationCollection;
  hint?: string;
  isDeletable?: boolean;
}

export enum EFormControlConfigurationAllowedFormat
{
  'string',
  'stringWithPrefixSelection',
  'number',
  'boolean',
  'date',
  'stringList',
  'collection'
}

export interface IAbstractFormService
{
  generateForm( configuration: IFormControlConfigurationCollection, formGroup?: FormGroup ): void;
  addControl( controlName: string, configuration: IFormControlConfiguration, formGroup: FormGroup ): void;
}
