import { Component, OnInit } from '@angular/core';
import { ScopeService } from '../core/scope/service/scope.service';
import { IProfileDisplayDataVehicle, IProfileSearchApiResponse, IVehicleData } from '../search/interfaces.defs';
import * as FileSaver from 'file-saver';
import * as moment from "moment";
import { BehaviorSubject, forkJoin } from 'rxjs';
import { ExportDataService } from './service/export-data.service';
import { IExportData, IExportProfileData, IP2PTripSummaryApiResponse, ISearchContractApiResponse } from './interfaces.defs';
import { ExportDataEnum } from './export-data.enum';
import {NgbCalendar, NgbDate}  from '@ng-bootstrap/ng-bootstrap';

const CSV_EXTENSION = '.csv';
const CSV_TYPE = 'text/plain;charset=utf-8';
const NOT_FOUND = "Not Found";
const csvHeader = {
  "vin": "VIN", "licencePlate": "Licence Plate",
  "vehicleName": "Vehicle Name", "uuid": "UUID",
  "role": "Role", "age": "Age (In Years)", "zipcode": "ZipCode",
  "communitySize": "Community Size", "driven": "% Driven"
};
const OWNER = 'OWNER';
const PEER = 'PEER';
const SME_OWNER = 'SME_OWNER';
const SME_PEER = 'SME_PEER';

@Component({
  selector: 'app-export-data',
  templateUrl: './export-data.component.html',
  styleUrls: ['./export-data.component.scss']
})
export class ExportDataComponent implements OnInit {
  public fromDate: NgbDate = this.calendar.getPrev(this.calendar.getToday(),'m',3);
  public toDate: NgbDate = this.calendar.getToday();
  public maxDate = this.calendar.getToday();
  public isVehicleDataReady = new BehaviorSubject<Boolean>(false);
  public isVehicleDataReady$ = this.isVehicleDataReady.asObservable();

  public isPercentDrivenDataReady = new BehaviorSubject<Boolean>(false);
  public isPercentDrivenDataReady$ = this.isPercentDrivenDataReady.asObservable();

  public showExportPageLoader = false;
  public exportErrorMessage: string[] = [];
  constructor(
    private exportDataService: ExportDataService,
    private scopeService: ScopeService,
    private calendar: NgbCalendar
  ) {

  }

  ngOnInit(): void { }

  public export(vinStr: string) {
    this.exportErrorMessage = [];
    let exportDataResult: IExportData[] = [];
    let selectedScopes = this.scopeService.currentScopeSelection.getValue();
    let vehicleDataMap: Map<string, IVehicleData> = new Map();
    let communitySizeMap: Map<string, number> = new Map();
    let percentDrivenMap:Map<string,number> =new Map();
    let vinArray: string[] = this.getVinArray(vinStr);
    //copying VINs to seprate array and use it to track vin that doesn't have data
    let defaultDataVinArray = vinArray.slice();
    vinStr = vinArray.join(",");

    if(!this.fromDate || !this.toDate){
      this.exportErrorMessage.push("FromDate and ToDate are required");
      return;
    }

    let fromDateMoment = moment(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day));
    let toDateMoment = moment(new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day));

    if (!this.isValidInputs(vinStr, selectedScopes,fromDateMoment,toDateMoment)) return;

    this.showExportPageLoader = true;
    //Vehicle API Call
    forkJoin(this.exportDataService.getVehicles(vinArray)).subscribe(
      (response) => {
        let errorVinArr: string[] = [];
        response.forEach((result => {
          let vehicleResponse: IProfileDisplayDataVehicle = result["p2pVehicleDisplay"];
          let vehicleError: string = result["vehicleError"];
          if (vehicleResponse) {
            let vehicleData: IVehicleData = { vin: vehicleResponse.vin, licencePlate: vehicleResponse.licencePlate, description: vehicleResponse.description };
            vehicleDataMap.set(vehicleResponse.vin, vehicleData);
          } else if (vehicleError && vehicleError.length > 0) {
            errorVinArr.push(vehicleError);
          }
        }))
        this.isVehicleDataReady.next(true);
        if (errorVinArr && errorVinArr.length > 0) {
          this.exportErrorMessage.push("P2P Vehicle Display Service API call failed for Vin:: " + errorVinArr.join(','));
        }

      }
    );

    //Contract API Call
    this.exportDataService.getContracts(vinStr, selectedScopes).subscribe((response: ISearchContractApiResponse) => {
      if (response.search && response.search.total > 0) {
        let contractResult = this.processContracts(response, defaultDataVinArray);
        communitySizeMap = contractResult['communitySizeMap']
        let exportDataResult: IExportData[] = contractResult['exportDataArr'];
        let uuids: string[] = contractResult['uuids'];

        //P2P-Trip-Summary Call
        this.exportDataService.getVehicleTripSummary(vinArray,uuids,fromDateMoment.format(),toDateMoment.format()).subscribe((response: IP2PTripSummaryApiResponse) => {
          this.processP2PTripSummaryData(response,vinArray,percentDrivenMap);
          this.isPercentDrivenDataReady.next(true);
        }, ((error) => {
          this.isPercentDrivenDataReady.next(true);
          this.exportErrorMessage.push("P2P-Trip-Summary API:: " + error.message);
        }))

        //User API Call
        this.exportDataService.getProfiles(uuids, selectedScopes).subscribe((response: IProfileSearchApiResponse) => {
          let userProfileMap: Map<string, IExportProfileData> = this.processProfileData(response);
          //Profile Data Enrichment
          exportDataResult = this.profileDataEnrichment(exportDataResult, userProfileMap);
          this.exportCustomerData(defaultDataVinArray, exportDataResult, vehicleDataMap, communitySizeMap, percentDrivenMap);
        }, ((error) => {
          this.exportErrorMessage.push("UserProfile Search API:: " + error.message);
          this.exportCustomerData(defaultDataVinArray, exportDataResult, vehicleDataMap, communitySizeMap, percentDrivenMap);
        }))

      } else {
        this.isPercentDrivenDataReady.next(true);
        this.exportCustomerData(defaultDataVinArray, exportDataResult, vehicleDataMap, communitySizeMap, percentDrivenMap);
      }
    }, ((error) => {
      this.exportErrorMessage.push("Contract Search API:: " + error.message);
      this.isPercentDrivenDataReady.next(true);
      this.exportCustomerData(defaultDataVinArray, exportDataResult, vehicleDataMap, communitySizeMap, percentDrivenMap);
    }));

  }

  private exportCustomerData(defaultDataVinArray: string[], exportDataResult: IExportData[], vehicleDataMap: Map<string, IVehicleData>, communitySizeMap: Map<string, number>, percentDrivenMap: Map<string, number>) {
    this.sortExportData(exportDataResult);
    this.addDefaultExportData(defaultDataVinArray, exportDataResult);
    this.isVehicleDataReady$.subscribe((isVehicleReady) => {
      if (isVehicleReady) {
        this.isPercentDrivenDataReady$.subscribe((isDrivenReady)=>{
          if (isDrivenReady) {
            //Final Data Enrichment
            exportDataResult = this.dataEnrichment(exportDataResult, vehicleDataMap, communitySizeMap, percentDrivenMap);
            //Exporting CSV File
            this.exportToCsv(exportDataResult, "CustomerData-" + moment().format());

            //Resetting Driven Behaviour Subject to subscribe it once
            this.isPercentDrivenDataReady = new BehaviorSubject<Boolean>(false);
            this.isPercentDrivenDataReady$ = this.isPercentDrivenDataReady.asObservable();
          }
        })
         //Resetting Vehicle Behaviour Subject to subscribe it once
         this.isVehicleDataReady = new BehaviorSubject<Boolean>(false);
         this.isVehicleDataReady$ = this.isVehicleDataReady.asObservable();
      }
    })
  }

  private sortExportData(exportDataResult: IExportData[]) {
    exportDataResult = exportDataResult.sort((exportData1, exportData2) => {
      if (exportData1.vin.toUpperCase() > exportData2.vin.toUpperCase()) {
        return 1;
      } else if (exportData1.vin.toUpperCase() < exportData2.vin.toUpperCase()) {
        return -1;
      } else {
        if (exportData1.role === OWNER) {
          return -1;
        } else if (exportData2.role === OWNER) {
          return 1;
        } else if (exportData1.role === PEER) {
          return -1;
        } else if (exportData2.role === PEER) {
          return 1;
        } else if (exportData1.role === SME_OWNER) {
          return -1;
        } else if (exportData2.role === SME_OWNER) {
          return 1;
        } else if (exportData1.role === SME_PEER) {
          return -1;
        } else if (exportData2.role === SME_PEER) {
          return 1;
        } else {
          return 0;
        }

      }
    });
  }

  private getVinArray(vinStr: string): string[] {
    let vinArray: string[] = [];
    vinStr.split(",").forEach((value => {
      value = value.trim();
      if (value.length > 0) {
        vinArray.push(value);
      }
    }))
    return vinArray;
  }

  private isValidInputs(vinStr: string, selectedScopes: string[],fromDateMoment:moment.Moment,toDateMoment:moment.Moment): boolean {
    if (!selectedScopes || selectedScopes.length === 0) {
      this.exportErrorMessage.push("Please provide at least one scope");
      return false;
    }

    if (!fromDateMoment.isValid() && !toDateMoment.isValid()) {
      this.exportErrorMessage.push("Please provide the valid fromDate and toDate");
      return false;
    }

    if (!fromDateMoment.isValid()) {
      this.exportErrorMessage.push("Please provide the valid fromDate");
      return false;
    }

    if (!toDateMoment.isValid()) {
      this.exportErrorMessage.push("Please provide the valid toDate");
      return false;
    }

    if (vinStr.length === 0) {
      this.exportErrorMessage.push("Please provide the comma separated vin list");
      return false;
    }

    return true;
  }
  private processContracts(response: ISearchContractApiResponse, defaultDataVinArray: string[]): object {
    let uuids: string[] = [];
    let exportDataArr: IExportData[] = [];
    let communitySizeMap: Map<string, number> = new Map();
    response.search.items.forEach((item) => {
      let vin = item.attributes['vin'];
      let exportData: IExportData = {
        vin: vin,
        licencePlate: NOT_FOUND,
        vehicleName: NOT_FOUND,
        uuid: NOT_FOUND,
        role: NOT_FOUND,
        age: NOT_FOUND,
        zipcode: NOT_FOUND,
        communitySize: NOT_FOUND,
        driven: 0
      };

      //User UUIDs
      if (item.userUuid && item.userUuid.length > 0) {
        uuids.push(item.userUuid);
        exportData.uuid = item.userUuid;
      }

      //Community Size
      if (communitySizeMap.has(vin)) {
        communitySizeMap.set(vin, communitySizeMap.get(vin) + 1);
      } else {
        communitySizeMap.set(vin, 1);
      }

      if (item.contractDefinitionName == ExportDataEnum.OWNER) {
        exportData.role = OWNER;
      } else if (item.contractDefinitionName == ExportDataEnum.PEER) {
        exportData.role = PEER;
      } else if (item.contractDefinitionName == ExportDataEnum.SME_OWNER) {
        exportData.role = SME_OWNER;
      } else if (item.contractDefinitionName == ExportDataEnum.SME_PEER) {
        exportData.role = SME_PEER;
      }

      const index = defaultDataVinArray.findIndex((vin => { return vin === exportData.vin }));
      if (index !== -1) {
        defaultDataVinArray.splice(index, 1);
      }
      exportDataArr.push(exportData);
    });

    return { "exportDataArr": exportDataArr, "communitySizeMap": communitySizeMap, "uuids": uuids } as object;
  }

  private processP2PTripSummaryData(response: IP2PTripSummaryApiResponse,vinArray:string[],percentDrivenMap:Map<string,number>) {
    let tripSummaryMap: Map<string, Map<string,number>> = new Map();
    let vinDrivenKmMap: Map<string,number> = new Map();

    response.p2pTripSummary.forEach((result => {
      if(result.drivenKilometers !== undefined){
        if(vinDrivenKmMap.has(result.vin)){
          vinDrivenKmMap.set(result.vin,vinDrivenKmMap.get(result.vin)+result.drivenKilometers);
        }else{
          vinDrivenKmMap.set(result.vin,result.drivenKilometers);
        }
        if (tripSummaryMap.has(result.vin)) {
          let userDrivenKmMap = tripSummaryMap.get(result.vin);
          if(userDrivenKmMap.has(result.userId)){
            userDrivenKmMap.set(result.userId,userDrivenKmMap.get(result.userId)+result.drivenKilometers);
          }else{
            userDrivenKmMap.set(result.userId,result.drivenKilometers);
          }
          tripSummaryMap.set(result.vin, userDrivenKmMap);
        } else {
          let userDrivenKmMap: Map<string, number> = new Map();
          userDrivenKmMap.set(result.userId,result.drivenKilometers);
          tripSummaryMap.set(result.vin, userDrivenKmMap);
        }
      }
    }))

    vinArray.forEach((vin => {
        if(vinDrivenKmMap.has(vin) && tripSummaryMap.has(vin)){
          let totalDrivenKMPerVin = vinDrivenKmMap.get(vin);
          let userDrivenMap = tripSummaryMap.get(vin);
          for (const [key, value] of userDrivenMap.entries()) {
            let percentDriven = 0;
            if(totalDrivenKMPerVin !==0){
              percentDriven = (value/totalDrivenKMPerVin)*100;
            }
            percentDrivenMap.set(vin+"_"+key,percentDriven);
          }

        }
    }))

  }

  private processProfileData(response: IProfileSearchApiResponse): Map<string, IExportProfileData> {
    let userProfileMap: Map<string, IExportProfileData> = new Map();
    response.search.items.forEach((item) => {
      let exportProfileData: IExportProfileData = { uuid: item.uuid, zipcode: NOT_FOUND, age: NOT_FOUND };
      if ((item.addresses && item.addresses.length > 0) && (item.addresses[0].postal && item.addresses[0].postal.length > 0)) {
        exportProfileData.zipcode = item.addresses[0].postal;
      }
      if (item.birthdate && item.birthdate.length > 0) {
        exportProfileData.age = String(moment().diff(item.birthdate, 'years'));
      }
      userProfileMap.set(item.uuid, exportProfileData);
    })
    return userProfileMap;
  }

  private addDefaultExportData(defaultDataVinArray: string[], defaultExportDataArr: IExportData[]) {
    defaultDataVinArray.forEach((vin => {
      let defaultExportData: IExportData =
      {
        vin: vin,
        licencePlate: NOT_FOUND,
        vehicleName: NOT_FOUND,
        uuid: NOT_FOUND,
        role: NOT_FOUND,
        age: NOT_FOUND,
        zipcode: NOT_FOUND,
        communitySize: NOT_FOUND,
        driven: 0
      };
      defaultExportDataArr.push(defaultExportData);
    }));

  }

  private dataEnrichment(exportDataResult: IExportData[], vehicleDataMap: Map<string, IVehicleData>, communitySizeMap: Map<string, number>, percentDrivenMap: Map<string, number>): IExportData[] {
    let dataEnrichmentResult: IExportData[] = [];
      exportDataResult.forEach((exportData) => {
        // Vehicle Data
        if (vehicleDataMap && vehicleDataMap.has(exportData.vin)) {
          exportData.licencePlate = vehicleDataMap.get(exportData.vin).licencePlate;
          exportData.vehicleName = vehicleDataMap.get(exportData.vin).description;
        }
        //CommunitySize Data
        if (communitySizeMap && communitySizeMap.has(exportData.vin)) {
          exportData.communitySize = String(communitySizeMap.get(exportData.vin));
        }
        // % Driven Data
        if (percentDrivenMap && percentDrivenMap.has(exportData.vin+"_"+exportData.uuid)){
            exportData.driven = percentDrivenMap.get(exportData.vin+"_"+exportData.uuid);
        }

        dataEnrichmentResult.push(exportData);
      })
    return dataEnrichmentResult;
  }

  private profileDataEnrichment(exportDataArr: IExportData[], userProfileMap: Map<string, IExportProfileData>): IExportData[] {
    let exportDataResult: IExportData[] = [];
    exportDataArr.forEach((exportData) => {
      if (userProfileMap.has(exportData.uuid)) {
        exportData.age = userProfileMap.get(exportData.uuid).age;
        exportData.zipcode = userProfileMap.get(exportData.uuid).zipcode;
      }
      exportDataResult.push(exportData)
    });
    return exportDataResult;
  }
  /**
   * Saves the file on the client's machine via FileSaver library.
   *
   * @param buffer The data that need to be saved.
   * @param fileName File name to save as.
   * @param fileType File type to save as.
   */
  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    this.showExportPageLoader = false;
    const data: Blob = new Blob([buffer], { type: fileType });
    FileSaver.saveAs(data, fileName);
  }

  /**
   * Creates an array of data to CSV. It will automatically generate a title row based on object keys.
   *
   * @param rows array of data to be converted to CSV.
   * @param fileName filename to save as.
   * @param columns array of object properties to convert to CSV. If skipped, then all object properties will be used for CSV.
   */
  public exportToCsv(rows: object[], fileName: string, columns?: string[]): string {
    let csvHeaderArr: string[] = [];

    if (!rows || !rows.length) {
      this.showExportPageLoader = false;
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]).filter(k => {
      if (columns?.length) {
        return columns.includes(k);
      } else {
        return true;
      }
    });

    keys.forEach((key => {
      csvHeaderArr.push(csvHeader[key]);
    }))
    const csvContent =
      csvHeaderArr.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');
    this.saveAsFile(csvContent, `${fileName}${CSV_EXTENSION}`, CSV_TYPE);
  }

}
