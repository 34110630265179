import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication/service/authentication.service';
import { switchMap } from 'rxjs/operators';
import { ScopeService } from './scope/service/scope.service';
import { API_ENDPOINT_MAP } from './api/api.service';

@Injectable()
export class ODMHttpInterceptor implements HttpInterceptor
{
  constructor(
    private authenticationService: AuthenticationService,
    private scopeService: ScopeService
  )
  {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>>
  {
    return this.authenticationService.getToken()
      .pipe(
        switchMap( ( IDToken: string ) => {
          const scope: string = this.scopeService.currentScopeId.getValue();
          const loginEndpointPrefix = API_ENDPOINT_MAP.authentication._prefix,
            cloneOptions: IRequestCloneOptions<string> = <IRequestCloneOptions<string>>{
              setHeaders: { 'Accept': 'application/json' }
            }
          ;

          if ( IDToken ) {
            cloneOptions.setHeaders['Authorization'] = `Bearer ${IDToken}`;
          }

          if ( !request.url.match( new RegExp( loginEndpointPrefix ) ) ) {
            cloneOptions.setHeaders['X-ODM-Scope'] = scope;
          }

          return next.handle( request.clone( cloneOptions ) );
        } )
      );
  }
}

interface IRequestCloneOptions<T> {
  headers?: HttpHeaders;
  reportProgress?: boolean;
  params?: HttpParams;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
  body?: T | null;
  method?: string;
  url?: string;
  setHeaders?: {
    [name: string]: string | string[];
  };
  setParams?: {
    [param: string]: string;
  };
}
