import {
  EFormControlConfigurationAllowedFormat,
  IAbstractFormService,
  IFormControlConfiguration,
  IFormControlConfigurationCollection
} from './interfaces.defs';
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {EventEmitter} from '@angular/core';
import {IApiResponseError, IApiResponseErrorDetail} from '../../interfaces.defs';
import {Subject} from 'rxjs';

export abstract class AbstractFormService implements IAbstractFormService
{
  protected _form: FormGroup;

  public resetForm: Subject<boolean> = new Subject<boolean>();

  public generateForm( configuration: IFormControlConfigurationCollection, formGroup: FormGroup = new FormGroup( {} ) ): void
  {
    Object.keys( configuration ).forEach( ( controlName: string ) => {
      this.addControl( controlName, configuration[controlName], formGroup );

      if ( configuration[controlName].format === EFormControlConfigurationAllowedFormat.collection ) {
        this.generateForm( <IFormControlConfigurationCollection>configuration[controlName].value, <FormGroup>formGroup.get( controlName ) );
      }
    } );
    this._form = formGroup;
  }

  public addControl( controlName: string, controlConfiguration: IFormControlConfiguration, formGroup: FormGroup ): void
  {
    const defaultValue = (controlConfiguration.format === EFormControlConfigurationAllowedFormat.date)
      ? null
      : '',
      controlState = {
        value: controlConfiguration.value || defaultValue,
        disabled: controlConfiguration.disabled || false
      },
      control = ( controlConfiguration.format === EFormControlConfigurationAllowedFormat.collection )
        ? new FormGroup( {} )
        : new FormControl( controlState, this.generateFormControlValidators( controlConfiguration ) )
    ;

    if ( controlConfiguration.format !== EFormControlConfigurationAllowedFormat.collection ) {
      control.valueChanges.subscribe( ( value: string ) => {
        controlConfiguration.value = value;
      } );
    }

    formGroup.addControl( controlName, control );
  }

  private generateFormControlValidators( configuration: IFormControlConfiguration ): ValidatorFn[]
  {
    let validators: ValidatorFn[] = [];

    if ( configuration.required && configuration.format === EFormControlConfigurationAllowedFormat.boolean) {
      validators.push( Validators.requiredTrue );
    } else if ( configuration.required ) {
      validators.push( Validators.required );
    }

    if ( configuration.pattern ) {
      validators.push( Validators.pattern( configuration.pattern ) );
    }

    if ( configuration.format === EFormControlConfigurationAllowedFormat.number ) {
      if ( configuration.pattern === undefined ) {
        configuration.pattern = '([\\d]+[.]*[\\d]+|\\d)';
      }
      validators.push( Validators.pattern( configuration.pattern ) )
    }

    if ( configuration.format === EFormControlConfigurationAllowedFormat.stringList ) {
      validators.push( ( control: AbstractControl ) => {
        if ( configuration.allowedValues.includes( control.value ) ) {
          return null;
        }

        return { 'invalid-value': true };
      } );
    }

    return validators;
  }

  public resetFormControls( configuration: IFormControlConfigurationCollection, formGroup: FormGroup = this._form ): void
  {
    Object.keys( formGroup.controls ).forEach( ( controlName: string ) => {
      if ( !configuration[controlName] ) {
        formGroup.removeControl( controlName );
      }

      if ( configuration[controlName] && configuration[controlName].format === EFormControlConfigurationAllowedFormat.collection ) {
        this.resetFormControls( <IFormControlConfigurationCollection>configuration[controlName].value, <FormGroup>formGroup.get( controlName ) );
      }
    } );

    setTimeout( () => {
      formGroup.markAsPristine();
      formGroup.markAsUntouched();
    } );
  }

  protected triggerFormValidation( form: FormGroup )
  {
    Object.keys( form.controls ).forEach( controlName =>
    {
      const control: AbstractControl = form.get( controlName );

      if ( control instanceof FormControl ) {
        control.markAsTouched( { onlySelf: true } );
        (<EventEmitter<any>> control.statusChanges).emit( 'TOUCHED' );
      } else if ( control instanceof FormGroup ) {
        this.triggerFormValidation( control );
      }
    } );
    form.updateValueAndValidity();
  }

  protected errorHandler( errors: IApiResponseError )
  {
    errors.details && errors.details.forEach( ( error: IApiResponseErrorDetail ) =>
    {
      const control = this._form.get( error.target ),
        errorResponseObject = {};

      if ( control ) {
        errorResponseObject[ error.code ] = error.message;
        control.setErrors( errorResponseObject );
        control.markAsTouched( { onlySelf: true } );
        (<EventEmitter<any>> control.statusChanges).emit( 'TOUCHED' );
      }
    } );
  }

}
