import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from "../service/authentication.service";
import { EPageNames } from "../../../interfaces.defs";
import { map } from 'rxjs/operators';
import {ScopeService} from "../../scope/service/scope.service";
import {AuthorizationService} from "../../authorization/authorization.service";
import {VisibilityService} from "../../visibility/service/visibility.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private visibilityService: VisibilityService,
    private scopeService: ScopeService,
    private router: Router
  )
  {}


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    return this.authenticationService.getTokenAndValidate()
      .pipe(
        map( ( isAuthenticated: boolean ) => {
        if ( !isAuthenticated ) {
          this.router.navigate( [ EPageNames.LOGIN ] );
        }
        return isAuthenticated;
      } ) );
  }

}
