import { Component, OnDestroy, OnInit } from '@angular/core';
import { IScopeDisplay } from '../../../top-bar/interfaces.defs';
import { ScopeService } from '../service/scope.service';
import { FormControl } from '@angular/forms';
import { forkJoin, ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component( {
  selector: 'scope-display',
  templateUrl: './scope-display.component.html',
  styleUrls: [ './scope-display.component.scss' ]
} )
export class ScopeDisplayComponent implements OnInit, OnDestroy
{
  private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>( 1 );

  public currentScope: FormControl = new FormControl('');
  public scopeList: IScopeDisplay[] = [];

  constructor( private scopeService: ScopeService ) { }

  ngOnInit()
  {
    forkJoin( {
      scopeList:  this.scopeService.getScopeList(),
      scopeTranslations:  this.scopeService.getScopeTranslations( 'en' )
    } )
      .subscribe( ( data ) =>
      {
        const availableScopes = data.scopeList,
          translatedScopes = data.scopeTranslations.scope
        ;

        for ( let i = 0, j = availableScopes.length; i < j; i++ ) {
          const scope = availableScopes[i],
            translatedScope = translatedScopes[ availableScopes[i] ]
              ? translatedScopes[ availableScopes[i] ]
              : availableScopes[i]
          ;

          this.scopeList.push( {
            id: scope,
            viewText: translatedScope
          } );
        }

        const scopes: string[] = this.scopeService.currentScopeSelection.getValue();
        this.currentScope.setValue( scopes, { emitEvent: false } );

        this.currentScope.valueChanges
          .subscribe( ( scopes: string[] ) => {
            this.scopeService.selectScopes( scopes );
          } );

        this.scopeService.resetScopeValue
          .pipe(
            filter( shouldResetScope => shouldResetScope ),
            takeUntil( this.destroyed )
          )
          .subscribe( () => {
            let scopes: string[] = this.scopeService.currentScopeSelection.getValue();
            this.currentScope.setValue( scopes, { emitEvent: false } );
          } )
      } );
  }

  ngOnDestroy(): void
  {
    this.destroyed.next( true );
    this.destroyed.complete();
  }

  public allSelected(): boolean {
    let availableScopes = this.scopeService.scopeList.getValue();
    let selectedScopes = this.scopeService.currentScopeSelection.getValue();
    return selectedScopes && availableScopes.length === selectedScopes.length;
  }

  public toggleAllSelection(): void {
    let scopes = this.scopeService.scopeList.getValue();
    this.currentScope.setValue( scopes );
    this.scopeService.selectScopes( scopes );
  }

  public onScopeChange( scopes: string[] ): void  {
    this.currentScope.setValue( scopes );
    this.scopeService.selectScopes( scopes );
  }
}
