import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SelectionService } from '../../core/selection/service/selection.service';
import { ProfileService } from '../../profile/service/profile.service';
import { NotificationService } from '../../shared/notification/notification.service';
import { IProfileDisplayDataContactDetail } from '../../search/interfaces.defs';
import { ESelectionName } from '../../core/selection/interfaces.defs';
import { filter, take } from 'rxjs/operators';
import { ContactDetailsService } from '../../profile/service/contact-details.service';
import { VisibilityService } from "../../core/visibility/service/visibility.service";

@Component( {
  selector: 'app-profile-view-contact-details',
  templateUrl: './profile-view-contact-details.component.html',
  styleUrls: [ '../action-bar.components.scss' ]
} )
export class ProfileViewContactDetailsComponent implements OnInit
{
  private rootRoute: ActivatedRoute;
  private uuid: string;

  constructor(
    private selectionService: SelectionService,
    private router: Router,
    private notificationService: NotificationService,
    public location: Location,
    public profileService: ProfileService,
    public contactDetailsService: ContactDetailsService,
    public visibilityService: VisibilityService
  ) {}

  ngOnInit()
  {
    this.rootRoute = this.router.routerState.root.children[0];

    const primaryRoute: ActivatedRoute = this.rootRoute.children.filter( ( route: ActivatedRoute ) => route.outlet === 'primary' )[0];

    this.uuid = primaryRoute.snapshot.paramMap.get( 'uuid' );
  }

  deleteContactDetail()
  {
    const contactDetailsSelection = this.selectionService.getSelectionCollection<IProfileDisplayDataContactDetail>( ESelectionName.CUSTOMER_DISPLAY_DATA_SELECTION ),
      currentContactDetails = contactDetailsSelection.selected[ 0 ]
    ;

    this.contactDetailsService.delete( this.uuid, currentContactDetails.address )
      .pipe(
        take( 1 ),
        filter( contactDetails => contactDetails === null)
      )
      .subscribe( () => {
        this.notificationService.showSuccessMessage( 'Contact details successfully deleted.' );
        this.profileService.showProfileButtons();
        this.profileService.refresh( this.uuid );
      } );
  }

  public preferContactDetail(): void
  {
    const contactDetailsSelection = this.selectionService.getSelectionCollection<IProfileDisplayDataContactDetail>( ESelectionName.CUSTOMER_DISPLAY_DATA_SELECTION ),
      currentContactDetails = contactDetailsSelection.selected[ 0 ]
    ;

    this.contactDetailsService.setPreferred( this.uuid, currentContactDetails.address )
      .pipe(
        take( 1 ),
        filter( contactDetails => contactDetails !== null),
      )
      .subscribe( () => {
        this.notificationService.showSuccessMessage( 'Contact detail successfully marked as preferred.' );
        this.profileService.showProfileButtons();
        this.profileService.refresh( this.uuid );
      } );
  }

  public resendConfirmationNotification(): void
  {
    const contactDetailsSelection = this.selectionService.getSelectionCollection<IProfileDisplayDataContactDetail>( ESelectionName.CUSTOMER_DISPLAY_DATA_SELECTION )
    ;

    this.contactDetailsService.contactDetails = contactDetailsSelection.selected[ 0 ];

    this.contactDetailsService.create( this.uuid )
      .pipe(
        take( 1 ),
        filter( contactDetails => contactDetails !== null)
      )
      .subscribe( () => {
        this.notificationService.showSuccessMessage( 'Confirmation notification has been successfully resent.' );
      } );

  }
}
