<mat-form-field *ngIf="visibilityService.searchCriteriaBarVisibility | async" appearance="standard">
  <mat-label>Search Filters:</mat-label>
  <mat-chip-list [selectable]="false">
    <mat-chip *ngFor="let chip of chipsList" removable (removed)="removeSearchFilter( chip )">
      {{chip.name}}: {{chip.value}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <mat-chip aria-label="Clear all filters" (click)="clearSearchFilters()" class="clearFiltersChip" [disableRipple]="true">Clear all</mat-chip>
  </mat-chip-list>
  <input matInput matSuffix (keydown.enter)="applyGenericFilter( $event )" (click)="$event.stopPropagation()" placeholder="Filter">
</mat-form-field>
