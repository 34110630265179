import { Injectable } from '@angular/core';
import { IContainerState, IFrameContainerStrategy, ParentContainerStrategy } from "../interfaces.defs";

@Injectable( {
  providedIn: 'root'
} )
export class ContainerStateService
{
  private readonly isInIframe: boolean = false;
  private _strategy: IContainerState;

  constructor()
  {
    if ( window.self !== window.top ) {
      this.isInIframe = true;
    }

    this.initializeContainerStateStrategy();
  }

  public initializeContainerStateStrategy(): void
  {
    if ( this.isInIframe ) {
      this._strategy = new IFrameContainerStrategy();
    } else {
      this._strategy = new ParentContainerStrategy();
    }
  }

  set strategy( value: IContainerState )
  {
    throw new Error( 'Container state strategy cannot be set.' );
  }

  get strategy(): IContainerState
  {
    return this._strategy;
  }

  public getParentStrategy(): ParentContainerStrategy
  {
    return new ParentContainerStrategy();
  }

  public getIFrameStrategy(): IFrameContainerStrategy
  {
    return new IFrameContainerStrategy();
  }
}
