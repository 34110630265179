import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { ISearchCriteria, ISearchResultItem } from '../interfaces.defs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { EOutletNames, EPageNames } from '../../interfaces.defs';
import { merge, ReplaySubject } from 'rxjs';
import { SelectionService } from '../../core/selection/service/selection.service';
import { ESelectionName } from '../../core/selection/interfaces.defs';
import { SearchResultsService } from '../service/search-results.service';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SearchService } from '../service/search.service';
import { ScopeService } from '../../core/scope/service/scope.service';

@Component( {
  selector: 'search-results',
  templateUrl: './search-results.component.html',
  styleUrls: [ './search-results.component.scss' ]
} )
export class SearchResultsComponent implements OnInit, OnDestroy, AfterViewInit
{
  private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>( 1 );
  private currentSearchCriteria: ISearchCriteria = null;
  public displayedColumns: string[] = [ 'select', 'firstName', 'lastName', 'username', 'phoneNumber', 'birthDate', 'address', 'status' ];

  private readonly DEFAULT_SORT_FIELD = 'username';
  private readonly DEFAULT_SORT_DIRECTION = 'asc';
  private readonly DEFAULT_PAGE_INDEX = 0;
  private readonly DEFAULT_PAGE_SIZE = 5;

  @ViewChild( MatPaginator, { static: true } ) paginator: MatPaginator;
  @ViewChild( MatSort, { static: true } ) sort: MatSort;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private selectionService: SelectionService,
    private searchResultsService: SearchResultsService,
    private searchService: SearchService,
    private scopeService: ScopeService
  )
  {
    this.selectionService.setSelectionCollection<ISearchResultItem>( ESelectionName.CUSTOMER_DISPLAY_DATA );
  }

  ngOnInit(): void
  {
    this.router.navigate( [ {
      outlets: {
        'action-bar': [ EOutletNames.ACTION_BAR, EPageNames.ACTION_BAR_SELECTION_NONE ]
      }
    } ], { relativeTo: this.route.parent, queryParamsHandling: 'preserve', skipLocationChange: true } );

    this.selection.changed
      .pipe(
        debounceTime( 100 ),
        takeUntil( this.destroyed )
      )
      .subscribe( ( changes: SelectionChange<ISearchResultItem> ) =>
      {
        const numSelected: number = changes.source.selected.length;

        if ( numSelected < 1 ) {
          this.router.navigate( [ {
            outlets: {
              'action-bar': [ EOutletNames.ACTION_BAR, EPageNames.ACTION_BAR_SELECTION_NONE ]
            }
          } ], { relativeTo: this.route.parent, queryParamsHandling: 'preserve', skipLocationChange: true } );
        }

        if ( numSelected === 1 ) {
          this.router.navigate( [ {
            outlets: {
              'action-bar': [ EOutletNames.ACTION_BAR, EPageNames.ACTION_BAR_SELECTION_ONE ]
            }
          } ], { relativeTo: this.route.parent, queryParamsHandling: 'preserve', skipLocationChange: true } );
        }

        if ( numSelected > 1 ) {
          this.router.navigate( [ {
            outlets: {
              'action-bar': [ EOutletNames.ACTION_BAR, EPageNames.ACTION_BAR_SELECTION_MULTIPLE ]
            }
          } ], { relativeTo: this.route.parent, queryParamsHandling: 'preserve', skipLocationChange: true } );
        }
      } );

    this.searchService.getSearchFilters()
      .subscribe( ( searchCriteria: ISearchCriteria ) => {
        this.currentSearchCriteria = searchCriteria;

        this.search(
          this.DEFAULT_SORT_FIELD,
          this.DEFAULT_SORT_DIRECTION,
          this.DEFAULT_PAGE_INDEX,
          this.DEFAULT_PAGE_SIZE
        );
      } );

    this.scopeService.onScopeChange()
      .pipe(
        takeUntil( this.destroyed )
      )
      .subscribe( () => {
        this.searchService.refresh();
      } )
  }

  ngOnDestroy(): void
  {
    this.destroyed.next( true );
    this.destroyed.complete();
  }

  ngAfterViewInit(): void
  {
    this.sort.sortChange
      .pipe(
        takeUntil( this.destroyed )
      )
      .subscribe( () => this.paginator.pageIndex = 0 );

    merge( this.sort.sortChange, this.paginator.page )
      .pipe(
        takeUntil( this.destroyed )
      )
      .subscribe( () =>
      {
        this.search(
          this.sort.active,
          this.sort.direction,
          this.paginator.pageIndex,
          this.paginator.pageSize
        );
      } );
  }

  private search( sort: string, order: string, page: number, pageSize: number ): void
  {

    Object.keys( this.currentSearchCriteria ).forEach( (key: string) => {
      if ( this.currentSearchCriteria[ key ] === null ) {
        delete this.currentSearchCriteria[ key ];
      }
    } );

    this.currentSearchCriteria[ 'sort' ] = sort;
    this.currentSearchCriteria[ 'order' ] = order;
    this.currentSearchCriteria[ 'page' ] = page * pageSize;
    this.currentSearchCriteria[ 'pageSize' ] = pageSize;

    this.searchResultsService.search( { ...this.currentSearchCriteria } )
      .pipe(
        takeUntil( this.destroyed )
      )
      .subscribe();

    this.selection.clear();
  }

  toggleRowSelection( event: MatCheckboxChange, row: ISearchResultItem ): void
  {
    event ? this.selection.toggle( row ) : null;
  }

  get isLoadingResults(): boolean {
    return !this.searchResultsService.isDataLoaded.value;
  }

  get searchResultsLength(): number {
    return this.searchResultsService.searchResultsLength;
  }

  get searchResults(): ISearchResultItem[] {
    return this.searchResultsService.searchResults;
  }

  get selection(): SelectionModel<ISearchResultItem> {
    return this.selectionService.getSelectionCollection<ISearchResultItem>( ESelectionName.CUSTOMER_DISPLAY_DATA )
  }
}
