import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Location } from '@angular/common';
import { ProfileService } from '../../profile/service/profile.service';
import { NotificationService } from '../../shared/notification/notification.service';
import { filter, take } from 'rxjs/operators';
import { EPageNames } from '../../interfaces.defs';
import { IProfileDisplayData } from '../../search/interfaces.defs';
import { ActivatedRoute, Router } from '@angular/router';
import { ScopeService } from "../../core/scope/service/scope.service";

@Component( {
  selector: 'app-profile-add',
  templateUrl: './profile-add.component.html',
  styleUrls: [ '../action-bar.components.scss' ]
} )
export class ProfileAddComponent implements OnInit, OnDestroy
{
  private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>( 1 );
  private rootRoute: ActivatedRoute;

  constructor(
    private notificationService: NotificationService,
    private router: Router,
    private scopeService: ScopeService,
    public location: Location,
    public profileService: ProfileService
  )
  {
  }

  ngOnInit()
  {
    this.rootRoute = this.router.routerState.root.children[0];
    let scope = this.scopeService.currentScopeSelection.getValue()[0];
    this.scopeService.currentScopeId.next( scope );

    const primaryRoute: ActivatedRoute = this.rootRoute.children.filter( ( route: ActivatedRoute ) => route.outlet === 'primary' )[0];
  }

  ngOnDestroy(): void
  {
    this.destroyed.next( true );
    this.destroyed.complete();
  }

  public addProfile(): void
  {
    this.profileService.create()
      .pipe(
        take( 1 ),
        filter( profile => profile !== null)
      )
      .subscribe( ( profile: IProfileDisplayData ) => {
        this.notificationService.showSuccessMessage( 'Profile successfully created.' );

        this.router.navigate( [ EPageNames.PROFILE_EDIT, profile.user.uuid ], { relativeTo: this.rootRoute } );
      } );
  }

  public resetForm(): void
  {
    this.profileService.resetForm.next( true );
  }

  get currentScope(): BehaviorSubject<string> {
    return this.scopeService.currentScopeId;
  }
}
