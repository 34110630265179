import { SelectionModel } from '@angular/cdk/collections';

export interface ISelectionCollection<T> {
  [key: string]: SelectionModel<T>;
}

export enum ESelectionName {
  CUSTOMER_DISPLAY_DATA = 'customerDisplayData',
  CUSTOMER_DISPLAY_DATA_SELECTION = 'customerDisplayDataSelection'
}
