import { Component } from '@angular/core';

@Component( {
  selector: 'app-preferred-contact-details-confirmation-dialog',
  templateUrl: './preferred-contact-details-confirmation-dialog.component.html',
  styleUrls: [ './preferred-contact-details-confirmation-dialog.component.scss' ]
} )
export class PreferredContactDetailsConfirmationDialogComponent
{
  constructor()
  {
  }
}
