export enum EPageNames
{
  LOGIN = 'login',
  HOME = '/',
  DASHBOARD = 'dashboard',
  SEARCH = 'search',
  SEARCH_RESULTS = 'results',
  PROFILE = 'profile',
  PROFILE_SME = 'profile-sme',
  EVENT = 'event',
  PROFILE_VIEW = 'view',
  PROFILE_VIEW_SME = 'view-sme',
  HISTORY_VIEW = 'view-history',
  PROFILE_EDIT = 'edit',
  PROFILE_ADD = 'add',
  PROFILE_EDIT_ADDRESS = 'edit-address',
  PROFILE_EDIT_ATTRIBUTE = 'edit-attribute',
  PROFILE_EDIT_CONTRACT = 'edit-contract',
  PROFILE_ADD_CONTACT_DETAILS = 'add-contact-details',
  PROFILE_ADD_ADDRESS = 'add-address',
  PROFILE_ADD_CONTRACT = 'add-contract',
  PROFILE_ADD_ATTRIBUTE = 'add-attribute',
  ACTION_BAR_SELECTION_NONE = 'action-bar-selection-none',
  ACTION_BAR_SELECTION_ONE = 'action-bar-selection-one',
  ACTION_BAR_SELECTION_MULTIPLE = 'action-bar-selection-multiple',
  ACTION_BAR_PROFILE_VIEW = 'action-bar-profile-view',
  ACTION_BAR_PROFILE_ADD_MEMBER = 'action-bar-profile-add-member',
  ACTION_BAR_PROFILE_ADD = 'action-bar-profile-add',
  ACTION_BAR_PROFILE_EDIT = 'action-bar-profile-edit',
  ACTION_BAR_PROFILE_VIEW_CONTACT_DETAILS = 'action-bar-profile-view-contact-details',
  ACTION_BAR_PROFILE_VIEW_ADDRESS = 'action-bar-profile-view-address',
  ACTION_BAR_PROFILE_VIEW_CONTRACT = 'action-bar-profile-view-contract',
  ACTION_BAR_PROFILE_VIEW_ATTRIBUTE = 'action-bar-profile-view-attribute',
  ACTION_BAR_CONTACT_DETAILS_ADD = 'action-bar-contact-details-add',
  ACTION_BAR_ADDRESS_EDIT = 'action-bar-address-edit',
  ACTION_BAR_ADDRESS_ADD = 'action-bar-address-add',
  ACTION_BAR_CONTRACT_ADD = 'action-bar-contract-add',
  ACTION_BAR_CONTRACT_EDIT = 'action-bar-contract-edit',
  ACTION_BAR_ATTRIBUTE_EDIT = 'action-bar-attribute-edit',
  ACTION_BAR_ATTRIBUTE_ADD = 'action-bar-attribute-add',
  ACTION_BAR_EVENT = 'action-bar-event'
}

export enum EOutletNames
{
  ACTION_BAR = 'action-bar'
}

export interface IApiResponse
{
  errors: IApiResponseErrorCollection
}

export interface IApiResponseErrorCollection
{
  [index: string]: IApiResponseError;
}

export interface IApiResponseError
{
  message: string;
  code: string | number;
  details?: IApiResponseErrorDetail[];
}

export interface IApiResponseErrorDetail
{
  code: string;
  target: string;
  message: string;
}

export interface IApiResponseErrorHandler
{
  ( error: IApiResponseError ): void;
}
