<mat-checkbox (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" class="mat-column-select">
</mat-checkbox>
<button mat-icon-button (click)="refresh()"><mat-icon>refresh</mat-icon></button>
<div class="flex-spacer"></div>
<div *ngFor="let actionButton of (scopeConfigurationService.currentScopeConfiguration | async)?.custGUI?.profileActionButtons">
  <button mat-raised-button (click)="scopeConfigurationService.performButtonAction(actionButton)">
    {{scopeConfigurationService.determineButtonName(actionButton)}}
  </button>
</div >
<button mat-raised-button (click)="showClientProfile()"><mat-icon>person</mat-icon>View Profile old</button>
<button mat-raised-button (click)="showClientSMEProfile()"><mat-icon>person</mat-icon>View Profile new</button>
<button mat-raised-button (click)="showClientHistory()"><mat-icon>settings_backup_restore</mat-icon>View History</button>
