<div class="flex-spacer"></div>
<button mat-raised-button color="primary" (click)="editAddress()"
        *ngIf="visibilityService.userManageFunctionalityVisibility | async">
  <mat-icon>edit</mat-icon>Edit Address
</button>
<button mat-raised-button color="warn" (click)="deleteAddress()"
        *ngIf="visibilityService.userManageFunctionalityVisibility | async">
  <mat-icon>delete</mat-icon>Delete Address
</button>

<app-profile-view></app-profile-view>
