import { Injectable } from '@angular/core';
import { ISearchCriteria, ISearchFilterChip } from '../interfaces.defs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, filter, share, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { EPageNames } from '../../interfaces.defs';

@Injectable( {
  providedIn: 'root'
} )
export class SearchService
{

  private _attributes: ISearchFilterChip[] = [];
  private _contractAttributes: ISearchFilterChip[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute
  )
  {
  }

  private static serializeQueryParameters( queryParameters: ISearchCriteria ): string
  {
    return btoa( JSON.stringify( queryParameters ) );
  }

  public static deserializeQueryParameters( searchCriteria: string ): ISearchCriteria
  {
    return JSON.parse( atob( searchCriteria ) );
  }

  public getSearchFilters(): Observable<ISearchCriteria>
  {
    return this.route.queryParams
      .pipe(
        filter( params => params.searchCriteria ),
        debounceTime( 500 ),
        distinctUntilChanged(),
        switchMap( ( params: Params ) =>
        {
          return of( SearchService.deserializeQueryParameters( params.searchCriteria ) );
        } ),
        share()
      );
  }

  public setSearchFilters( queryParameters: ISearchCriteria ): void
  {
    const serializedQueryParameters = SearchService.serializeQueryParameters( queryParameters ),
      searchQueryCriteria = {
        searchCriteria: serializedQueryParameters,
        time: Date.now()
      }
    ;

    this.router.navigate( [ EPageNames.SEARCH, EPageNames.SEARCH_RESULTS ], { queryParams: searchQueryCriteria } );
  }

  public addSearchFilter( filterValue: string ): void
  {
    this.getSearchFilters()
      .pipe(
        take( 1 )
      )
      .subscribe( ( searchCriteria: ISearchCriteria ) => {
        searchCriteria.keywords = filterValue;

        this.setSearchFilters( searchCriteria );
      } );
  }

  public removeFilter( key: string ): void
  {
    this.getSearchFilters()
      .pipe(
        take( 1 )
      )
      .subscribe( ( searchCriteria: ISearchCriteria ) =>
      {
        if (
          Object.keys( searchCriteria )
            .filter( searchCriteriaKey => searchCriteria[searchCriteriaKey] !== null && searchCriteria[searchCriteriaKey] !== '' )
            .length <= 1
        ) {
          this.removeAllFilters();
          return;
        }

        searchCriteria[ key ] = null;

        this.setSearchFilters( searchCriteria );
      } );
  }

  public removeAllFilters(): void
  {
    this.router.navigate( [EPageNames.HOME] );
  }

  public refresh(): void
  {
    this.route.queryParams
      .pipe(
        take( 1 )
      )
      .subscribe( ( queryParams: Params ) => {
        if ( queryParams.searchCriteria ) {
          const searchCriteria = SearchService.deserializeQueryParameters( queryParams.searchCriteria );
          this.setSearchFilters( searchCriteria );
        }
      } );
  }

  public addAttribute(context: string, key: string, value: string): void {
    const attribute: ISearchFilterChip = {
      name: `${context} ${key}: ${value}`,
      key: `attr.${context}.${key}`,
      value: value
    };
    this._attributes.push(attribute);
  }

  public removeAttribute(attribute: ISearchFilterChip): void {
    let index = this.attributes.indexOf( attribute );

    if (index > -1) {
      this._attributes.splice(index, 1);
    }
  }

  public removeAllAttributes(): void {
    this._attributes = [];
  }

  public addContractAttribute(key: string, value: string): void {
    const attribute: ISearchFilterChip = {
      name: `${key}: ${value}`,
      key: `c.attr.${key}`,
      value: value
    };
    this._contractAttributes.push( attribute );
  }

  public removeContractAttribute(contractAttribute: ISearchFilterChip): void {
    let index = this._contractAttributes.indexOf( contractAttribute );

    if (index > -1) {
      this._contractAttributes.splice(index, 1);
    }
  }

  public removeAllContractAttributes(): void {
    this._contractAttributes = [];
  }

  get attributes(): ISearchFilterChip[] {
    return this._attributes;
  }

  get contractAttributes(): ISearchFilterChip[] {
    return this._contractAttributes;
  }
}
