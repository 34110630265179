import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EPageNames } from '../../interfaces.defs';
import { VisibilityService } from '../../core/visibility/service/visibility.service';

@Component( {
  selector: 'home-display',
  templateUrl: './home-template.component.html',
  styleUrls: [ './home-template.component.scss' ]
} )
export class HomeTemplateComponent {
  private dragging: boolean;

  constructor(
    private router: Router,
    public visibilityService: VisibilityService
  ) {}

  public createProfile(): void {
    if ( this.dragging ) {
      this.dragging = false;
      return;
    }

    this.router.navigate( [ EPageNames.PROFILE, EPageNames.PROFILE_ADD ] );
  }

  dragStarted() {
    this.dragging = true;
  }
}
