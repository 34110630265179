import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EPageNames } from '../../interfaces.defs';
import { ProfileService } from '../../profile/service/profile.service';
import { VisibilityService } from "../../core/visibility/service/visibility.service";
import { ScopeConfigurationService } from "../../core/scope/service/scope-configuration.service";
import { BehaviorSubject } from "rxjs";
import { ScopeService } from "../../core/scope/service/scope.service";

@Component( {
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['../action-bar.components.scss']
} )
export class ProfileViewComponent implements OnInit
{

  private rootRoute: ActivatedRoute;
  private scope: string;
  private uuid: string;

  constructor(
    private router: Router,
    private scopeService: ScopeService,
    public profileService: ProfileService,
    public visibilityService: VisibilityService,
    public scopeConfigurationService: ScopeConfigurationService,
  )
  {
  }

  ngOnInit()
  {
    this.rootRoute = this.router.routerState.root.children[0];
    const primaryRoute: ActivatedRoute = this.rootRoute.children.filter( ( route: ActivatedRoute ) => route.outlet === 'primary' )[0];
    this.scope = primaryRoute.snapshot.paramMap.get( 'scope' );
    this.uuid = primaryRoute.snapshot.paramMap.get( 'uuid' );
  }

  public editProfile(): void
  {
    this.router.navigate( [ EPageNames.PROFILE_EDIT, this.uuid ], { relativeTo: this.rootRoute } );
  }

  public addProfile(): void
  {
    this.router.navigate( [ EPageNames.PROFILE_ADD, this.uuid ], { relativeTo: this.rootRoute } );
  }

  public addAttribute(): void
  {
    this.router.navigate( [ EPageNames.PROFILE_ADD_ATTRIBUTE, this.uuid ], { relativeTo: this.rootRoute } );
  }

  public addAddress(): void
  {
    this.router.navigate( [ EPageNames.PROFILE_ADD_ADDRESS, this.uuid ], { relativeTo: this.rootRoute } );
  }

  public addContactDetails(): void
  {
    this.router.navigate( [ EPageNames.PROFILE_ADD_CONTACT_DETAILS, this.scope, this.uuid ], { relativeTo: this.rootRoute } );
  }

  public addContract(): void
  {
    this.router.navigate( [ EPageNames.PROFILE_ADD_CONTRACT, this.uuid ], { relativeTo: this.rootRoute } );
  }

  public refresh(): void
  {
    this.profileService.refresh( this.uuid );
  }

  get currentScope(): BehaviorSubject<string> {
    return this.scopeService.currentScopeId;
  }
}
