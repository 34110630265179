<div id="wrapper">
  <div class="container" *ngIf="!showExportPageLoader">
    <div class="card shadow mb-4">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h6 class="text-primary fw-bold m-0">Export Customer Data</h6>
      </div>
      <div class="card-body">
        <scope-display></scope-display>
      <form class="row row-cols-sm-auto">
        <div class="col-4">
        <label for="fromDate">From:</label>
        <input  class="form-control" placeholder="yyyy-mm-dd" type="text" ngbDatepicker  [(ngModel)]="fromDate" #f="ngbDatepicker"  (click)="f.toggle()" name="fromDate" [maxDate]="maxDate" readonly/>
        </div>
        <div class="col-4">
          <label for="fromDate">To:</label>
          <input  class="form-control" placeholder="yyyy-mm-dd" type="text" ngbDatepicker [(ngModel)]="toDate" #t="ngbDatepicker"  (click)="t.toggle()" name="toDate" [maxDate]="maxDate" readonly/>
        </div>
      </form>
        <br/>
        <textarea class="form-control" rows="4" id="vin" placeholder="Please Enter Comma Separated  Vins" #vin></textarea>
        <br>
        <div class="text-center">
          <button class="btn btn-primary" (click)="export(vin.value)">Export</button>
        </div>
      </div>
    </div>
    <div class="container" *ngIf="(!showExportPageLoader && exportErrorMessage) && exportErrorMessage.length>0">
      <div class="alert alert-danger alert-dismissible">
        <strong>ErrorMessages!! </strong>
        <p *ngFor="let error of exportErrorMessage">&#9642; {{error}}</p>
      </div>
    </div>
  </div>

  <ng-container *ngIf="showExportPageLoader">
    <div style="padding-left: 45%; padding-top: 10%;">
      <mat-spinner></mat-spinner>
    </div>
  </ng-container>
</div>
