import { Inject, NgModule, RendererFactory2 } from '@angular/core';
import { NavigationEnd, PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { DOCUMENT } from '@angular/common';

import { filter } from 'rxjs/operators';
import { EPageNames } from './interfaces.defs';
import { AuthenticationDisplayComponent } from './core/authentication/display/authentication-display.component';
import { HomeTemplateComponent } from './home/display/home-template.component';
import { AuthenticationGuard } from './core/authentication/guard/authentication.guard';
import { HomeRedirectGuard } from './core/authentication/guard/home-redirect.guard';
import { DashboardComponent } from './home/dashboard/dashboard.component';
import { ExportDataComponent } from './export-data/export-data.component';


const routes: Routes = [
  { path: EPageNames.LOGIN, component: AuthenticationDisplayComponent, canActivate: [ HomeRedirectGuard ] },
  { path: EPageNames.SEARCH, component: HomeTemplateComponent, canActivate: [ AuthenticationGuard ],
    loadChildren: () => import('./search/search.module').then( module => module.SearchModule )
  },
  { path: EPageNames.PROFILE, component: HomeTemplateComponent, canActivate: [ AuthenticationGuard ],
    loadChildren: () => import('./profile/profile.module').then( module => module.ProfileModule )
  },
  { path: EPageNames.PROFILE_SME, component: HomeTemplateComponent, canActivate: [ AuthenticationGuard ],
    loadChildren: () => import('./profile-sme/profile-sme.module').then( module => module.ProfileSmeModule )
  },
  { path: EPageNames.EVENT, component: HomeTemplateComponent, canActivate: [ AuthenticationGuard ],
    loadChildren: () => import('./event/event.module').then( module => module.EventModule )
  },
  { path: 'exportData', component: ExportDataComponent, pathMatch: 'full' , canActivate: [ AuthenticationGuard ]},
  {
    path: '', component: HomeTemplateComponent, canActivate: [ AuthenticationGuard ], children: [
      { path: EPageNames.DASHBOARD, component: DashboardComponent },
      { path: '', component: DashboardComponent, pathMatch: 'full' },
    ]
  },
  { path: '**', redirectTo: EPageNames.HOME, pathMatch: 'full' }

];

@NgModule( {
  imports: [ RouterModule.forRoot( routes, {
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload'
  } ) ],
  exports: [ RouterModule ]
} )
export class AppRoutingModule
{
  private previousPageName: string;

  constructor(
    @Inject( DOCUMENT ) document: Document,
    rendererFactory: RendererFactory2,
    router: Router
  )
  {
    router.events
      .pipe(
        filter( event => event instanceof NavigationEnd )
      )
      .subscribe( ( event: NavigationEnd ) =>
      {
        const currentPageName = event.urlAfterRedirects
                .replace( /^\//, '' )
                .replace( /\//, '-' ),
              renderer = rendererFactory.createRenderer( null, null )
        ;

        if ( this.previousPageName ) {
          renderer.removeClass( document.body, this.previousPageName );
        }

        if ( currentPageName ) {
          renderer.addClass( document.body, currentPageName );
        }

        this.previousPageName = currentPageName;
      } );
  }
}
