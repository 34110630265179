import { Component, OnDestroy, OnInit } from '@angular/core';
import { IProfileDisplayData, ISearchResultItem } from '../../search/interfaces.defs';
import { SelectionModel } from '@angular/cdk/collections';
import { SelectionService } from '../../core/selection/service/selection.service';
import { SearchResultsService } from '../../search/service/search-results.service';
import { ESelectionName } from '../../core/selection/interfaces.defs';
import { filter, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { EPageNames } from '../../interfaces.defs';
import { Router } from '@angular/router';
import { SearchService } from '../../search/service/search.service';
import { ScopeConfigurationService } from "../../core/scope/service/scope-configuration.service";
import { ProfileService } from "../../profile/service/profile.service";
import { ScopeService } from "../../core/scope/service/scope.service";

@Component({
  selector: 'app-search-results-one-selection',
  templateUrl: './search-results-selection-one.component.html',
  styleUrls: ['../action-bar.components.scss']
})
export class SearchResultsSelectionOneComponent implements OnInit, OnDestroy
{
  private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>( 1 );
  private searchResults: ISearchResultItem[];

  public selection: SelectionModel<ISearchResultItem>;

  constructor(
    private selectionService: SelectionService,
    private searchService: SearchService,
    private profileService: ProfileService,
    private searchResultsService: SearchResultsService,
    private router: Router,
    private scopeService: ScopeService,
    public scopeConfigurationService: ScopeConfigurationService
  ) { }

  ngOnInit() {
    this.selection = this.selectionService.getSelectionCollection<ISearchResultItem>( ESelectionName.CUSTOMER_DISPLAY_DATA );

    if ( this.selection.selected && this.selection.selected.length === 1 ) {
      let selected = this.selection.selected[0];
      let scope = selected.scope;
      this.scopeService.currentScopeId.next( scope );
      this.scopeConfigurationService.reset();
      this.scopeConfigurationService.currentScope = scope;

      this.scopeConfigurationService.currentScopeConfiguration
        .pipe(
          takeUntil( this.destroyed ),
          filter( scopeConfiguration => scopeConfiguration !== null )
        )
        .subscribe( () => {
          this.profileService.get( selected.uuid )
            .pipe(
              filter(profile => profile !== null),
              takeUntil(this.destroyed)
            )
            .subscribe((profile: IProfileDisplayData) => {
              this.scopeConfigurationService.currentData = profile;
            });
        } );
    }

    this.searchResultsService.isDataLoaded
      .pipe(
        takeUntil( this.destroyed ),
        filter( isDataLoaded => isDataLoaded )
      )
      .subscribe( () => {
        this.searchResults = this.searchResultsService.searchResults;
      } );
  }

  ngOnDestroy(): void
  {
    this.destroyed.next( true );
    this.destroyed.complete();
  }

  isAllSelected() {
    const numSelected: number = this.selection.selected.length,
      numRows: number = this.searchResults.length
    ;

    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.searchResults.forEach(row => this.selection.select(row));
  }

  public showClientProfile(): void {
    let selected = this.selection.selected[0];
    let scope = selected.scope;
    let uuid = selected.uuid;
    this.router.navigate( [ EPageNames.PROFILE, EPageNames.PROFILE_VIEW, scope, uuid ] );
  }

  public showClientSMEProfile(): void {
    let selected = this.selection.selected[0];
    let scope = selected.scope;
    let uuid = selected.uuid;
    this.router.navigate( [ EPageNames.PROFILE_SME, EPageNames.PROFILE_VIEW_SME, scope, uuid ] );
  }

  public showClientHistory(): void   {
    let selected = this.selection.selected[0];
    let scope = selected.scope;
    let uuid = selected.uuid;
    this.router.navigate( [ EPageNames.EVENT, EPageNames.HISTORY_VIEW, scope, uuid ] );
  }

  refresh()
  {
    this.searchService.refresh();
  }
}
