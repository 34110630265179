import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from "@angular/animations";
import { AuthenticationService } from "../../core/authentication/service/authentication.service";
import { Router } from "@angular/router";
import { EPageNames } from "../../interfaces.defs";

@Component({
  selector: 'user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  animations: [
    trigger( 'toggleUserMenuVisibility', [
      transition( ':enter', [
        style( { opacity: 0, transform: 'translateY(-100px)' } ),
        animate( '200ms ease-out', style( { opacity: 1, transform: 'translateY(0)' } ) )
      ] ),
      transition( ':leave', animate( '200ms ease-out', style( { opacity: 0, transform: 'translateY(-100px)' } ) ) )
    ] )
  ]
})
export class UserMenuComponent implements OnInit {

  public isUserMenuOpen: boolean = false;

  @HostListener('document:click', ['$event']) clickOut( event: MouseEvent )
  {
    const userMenuComponentElement: HTMLElement = this.elementReference.nativeElement,
          dropdownPane: HTMLElement = document.querySelector( '.cdk-overlay-pane' )
    ;

    if (
      !userMenuComponentElement.contains( <Node>event.target )
      && !dropdownPane
    ) {
      if ( this.isUserMenuOpen ) {
        this.isUserMenuOpen = false;
      }
    }
  }

  constructor(
    private elementReference: ElementRef,
    private authenticationService: AuthenticationService,
    private router: Router
  ) { }

  ngOnInit() {
  }

  public toggleUserMenuVisibility( event: MouseEvent ): void
  {
    this.isUserMenuOpen = !this.isUserMenuOpen;
  }

  public logout(): void
  {
    this.authenticationService.logout();
    this.router.navigate( [ EPageNames.LOGIN ] );
  }

}
