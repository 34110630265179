import { NgModule } from '@angular/core';
import { SharedModule } from "../shared/shared.module";
import { CoreModule } from "../core/core.module";

import { SearchDisplayComponent } from './display/search-display.component';
import { SearchResultsComponent } from './results/search-results.component';
import { SearchFiltersComponent } from './filters/search-filters.component';
import { SearchRoutingModule } from './search-routing.module';


@NgModule( {
  declarations: [
    SearchDisplayComponent,
    SearchResultsComponent,
    SearchFiltersComponent
  ],
  imports: [
    CoreModule,
    SharedModule,
    SearchRoutingModule
  ],
  exports: [
    SearchDisplayComponent,
    SearchResultsComponent,
    SearchFiltersComponent
  ],
})
export class SearchModule { }
