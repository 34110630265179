import { Component, OnInit } from '@angular/core';
import {Location} from "@angular/common";

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['../action-bar.components.scss']
})
export class BaseComponent implements OnInit {

  constructor(
    public location: Location) { }

  ngOnInit(): void {
  }

}
