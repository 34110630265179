import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelectionService } from '../../core/selection/service/selection.service';
import { ISearchResultItem } from '../../search/interfaces.defs';
import { SelectionModel } from '@angular/cdk/collections';
import { ESelectionName } from '../../core/selection/interfaces.defs';
import { SearchResultsService } from '../../search/service/search-results.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { SearchService } from '../../search/service/search.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-search-results-no-selection',
  templateUrl: './search-results-selection-none.component.html',
  styleUrls: ['../action-bar.components.scss']
})
export class SearchResultsSelectionNoneComponent implements OnInit, OnDestroy
{
  private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>( 1 );
  private searchResults: ISearchResultItem[];

  public selection: SelectionModel<ISearchResultItem>;

  constructor(
    private selectionService: SelectionService,
    private searchService: SearchService,
    private searchResultsService: SearchResultsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.selection = this.selectionService.getSelectionCollection<ISearchResultItem>( ESelectionName.CUSTOMER_DISPLAY_DATA );

    this.searchResultsService.isDataLoaded
      .pipe(
        takeUntil( this.destroyed ),
        filter( isDataLoaded => isDataLoaded )
      )
      .subscribe( () => {
        this.searchResults = this.searchResultsService.searchResults;
      } );
  }

  ngOnDestroy(): void
  {
    this.destroyed.next( true );
    this.destroyed.complete();
  }

  isAllSelected() {
    const numSelected: number = this.selection.selected.length,
      numRows: number = this.searchResults.length
    ;

    return numSelected == numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.searchResults.forEach(row => this.selection.select(row));
  }

  refresh()
  {
    this.searchService.refresh();
  }
}
