import { EventEmitter, Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { AsyncSubject, BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';
import { IScopeApiResponse, IScopeListApiResponse } from '../interfaces.defs';
import { API_ENDPOINT_MAP, ApiService } from '../../api/api.service';
import { ScopeChangeConfirmationDialogComponent } from '../scope-change-confirmation-dialog/scope-change-confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EPageNames } from '../../../interfaces.defs';
import { AuthorizationService } from "../../authorization/authorization.service";

@Injectable( {
  providedIn: 'root'
} )
export class ScopeService
{
  private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>( 1 );

  private _scopeList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>( [] );
  private _scopeChange: EventEmitter<string[]> = new EventEmitter<string[]>( null );
  private _beforeScopeChange: EventEmitter<string[]> = new EventEmitter<string[]>( null );
  private _shouldChangeScope: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );
  private _currentScopeSelection: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  private _currentScopeId: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private _singleScopeSelected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public resetScopeValue: EventEmitter<boolean> = new EventEmitter<boolean>( false );

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private localStorage: StorageMap,
    private dialog: MatDialog,
    private router: Router
  )
  {
    this.authenticationService.subscribeToLogoutAction( () => {
      this._currentScopeId.next( '' );
      this._currentScopeSelection.next( null );
      this._singleScopeSelected.next( false );
      this.localStorage.delete( 'scopes' ).subscribe();
    } );

    this._currentScopeId.pipe( takeUntil( this.destroyed ) ).subscribe ( ( scope ) => {
      this.authorizationService.determineUserManagePermissionForScope( scope );
      this.authorizationService.determineContractManagePermissionForScope( scope );
      this.authorizationService.determineContractViewPermissionForScope( scope );
    } );

    this.authorizationService.permissions.pipe( takeUntil( this.destroyed ) ).subscribe( () => {
      let scope = this._currentScopeId.getValue();
      this.authorizationService.determineUserManagePermissionForScope( scope );
      this.authorizationService.determineContractManagePermissionForScope( scope );
      this.authorizationService.determineContractViewPermissionForScope( scope );
    });

    this._currentScopeSelection.pipe( takeUntil( this.destroyed ) ).subscribe( ( scopes: string[] ) => {
      let isSingleScopeSelected = scopes && scopes.length === 1;
      if ( isSingleScopeSelected ) {
        this._currentScopeId.next( scopes[0] );
        this._singleScopeSelected.next( isSingleScopeSelected );
      }
    });

    this._shouldChangeScope.pipe( takeUntil( this.destroyed ) )
      .subscribe( ( shouldChangeScope ) => {
        if ( shouldChangeScope ) {
          this.localStorage.set( 'scopes', this._currentScopeSelection.getValue() ).subscribe();
          this._scopeChange.emit( this._currentScopeSelection.getValue() );
        }
      } );

    this.localStorage.get( 'scopes' ).pipe( takeUntil( this.destroyed ) )
      .subscribe( (scopes: string[]) => {
        if ( scopes ) {
          this._currentScopeSelection.next( scopes );
          let isSingleScopeSelected = scopes.length === 1;
          if ( isSingleScopeSelected ) {
            this._currentScopeId.next( scopes[0] );
            this._singleScopeSelected.next( isSingleScopeSelected );
          }
        }
      });
  }

  public get scopeList(): BehaviorSubject<string[]>
  {
    return this._scopeList;
  }

  public getScopeList(): Observable<string[]>
  {
    return this.authenticationService.getToken()
      .pipe(
        switchMap( ( IDToken: string ) => {

          const options = {
              headers: new HttpHeaders( {
                'IDToken': IDToken
              } )
            },
            scopeUrl = API_ENDPOINT_MAP.authentication._prefix + API_ENDPOINT_MAP.authentication.scope
          ;

          return this.apiService.get<IScopeListApiResponse>( scopeUrl, null, options )
            .pipe(
              map( ( scopeList: IScopeListApiResponse ) => {
                let scopes: string[] = scopeList.custAuthentication;
                this._scopeList.next( scopes );
                return scopes;
              } )
            );
        } )
      );
  }

  public getScopeTranslations( languageCode: string ): Observable<IScopeApiResponse>
  {
    return this.authenticationService.getToken()
      .pipe(
        switchMap( ( IDToken: string ) =>
        {

          const options = {
            headers: new HttpHeaders( {
              'IDToken': IDToken
            } )
          };

          return this.apiService.get<IScopeApiResponse>( API_ENDPOINT_MAP.scope.get, { languageCode: languageCode }, options );
        } )
      );
  }

  public onScopeChange(): EventEmitter<string[]>
  {
    return this._scopeChange;
  }

  public onBeforeScopeChange(): EventEmitter<string[]>
  {
    return this._beforeScopeChange;
  }

  public selectScopes( scopes: string[] ): void
  {
    this._currentScopeSelection.next( scopes );
    let isSingleScopeSelected = scopes.length === 1;
    this._singleScopeSelected.next( isSingleScopeSelected );

    if ( this._beforeScopeChange.observers.length ) {
      this._beforeScopeChange.emit( scopes );
      return;
    }

    this._shouldChangeScope.next( true );
  }

  public onBeforeScopeChangeConfirm(): void {
    this._shouldChangeScope.next( false );

    const confirmedAction: AsyncSubject<boolean> = new AsyncSubject<boolean>(),
      dialogRef = this.dialog.open( ScopeChangeConfirmationDialogComponent )
    ;

    dialogRef.afterClosed()
      .subscribe( confirmation => {
        confirmedAction.next( confirmation );
        this._shouldChangeScope.next( confirmation );

        if ( !confirmation ) {
          this.resetScopeValue.emit( true );
        }

        confirmedAction.complete();
        this.dialog.closeAll();
      } );

    confirmedAction
      .pipe(
        filter( confirmation => confirmation )
      )
      .subscribe( () => {
        this.router.navigate( [EPageNames.HOME] );
      } );
  }

  get currentScopeSelection(): BehaviorSubject<string[]> {
    return this._currentScopeSelection;
  }

  get currentScopeId(): BehaviorSubject<string> {
    return this._currentScopeId;
  }

  get singleScopeSelected(): BehaviorSubject<boolean> {
    return this._singleScopeSelected;
  }
}
