<div class="loading-shade" *ngIf="isLoadingResults">
  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>
<mat-table [dataSource]="searchResults" matSort matSortActive="firstName" matSortDirection="asc" matSortDisableClear="true">

  <ng-container matColumnDef="select">
    <mat-header-cell *matHeaderCellDef></mat-header-cell>
    <mat-cell *matCellDef="let row">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        (change)="toggleRowSelection( $event, row )"
        [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="firstName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>First Name</mat-header-cell>
    <mat-cell *matCellDef="let result">{{result.firstname}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="lastName">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</mat-header-cell>
    <mat-cell *matCellDef="let result">{{result.lastname}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="username">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Username</mat-header-cell>
    <mat-cell *matCellDef="let result">{{result.username}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="phoneNumber">
    <mat-header-cell *matHeaderCellDef>Contact Details</mat-header-cell>
    <mat-cell *matCellDef="let result">{{result.contactDetails && result.contactDetails[0] && result.contactDetails[0].address}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="birthDate">
    <mat-header-cell *matHeaderCellDef>Birth Date</mat-header-cell>
    <mat-cell *matCellDef="let result">{{result.birthdate | date:'yyyy-MM-dd HH:mm:ss'}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="address">
    <mat-header-cell *matHeaderCellDef>Scope</mat-header-cell>
    <mat-cell *matCellDef="let result">{{result.scope}}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="status">
    <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
    <mat-cell *matCellDef="let result">{{result.status}}</mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></mat-row>
</mat-table>
<mat-paginator [pageSizeOptions]="[5, 10, 20]" [length]="searchResultsLength" showFirstLastButtons></mat-paginator>
