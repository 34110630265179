import { Injectable } from '@angular/core';
import { IContractDefinitionApiResponse, IProfileDisplayDataContractDefinition } from '../../search/interfaces.defs';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { API_ENDPOINT_MAP, ApiService } from '../../core/api/api.service';

export interface IProfileDisplayDataContractDefinitionSearchCriteria {
  uuid?: string,
  name?: string,
  status?: string,
  page?: number,
  pageSize?: number,
  order?: string,
  sort?: string
}

@Injectable( {
  providedIn: 'root'
} )
export class ContractDefinitionService
{
  private _definitions: BehaviorSubject<IProfileDisplayDataContractDefinition[]> = new BehaviorSubject<IProfileDisplayDataContractDefinition[]>( [] );
  private _definition: BehaviorSubject<IProfileDisplayDataContractDefinition> = new BehaviorSubject<IProfileDisplayDataContractDefinition>( null );

  constructor(
    private apiService: ApiService
  )
  {
  }

  public get definitions(): IProfileDisplayDataContractDefinition[]
  {
    return this._definitions.getValue();
  }

  public get definition(): IProfileDisplayDataContractDefinition
  {
    return this._definition.getValue();
  }

  public set definition( definitions: IProfileDisplayDataContractDefinition )
  {
    this._definition.next( definitions );
  }

  public getDefinitionObservable(): BehaviorSubject<IProfileDisplayDataContractDefinition>
  {
    return this._definition;
  }

  public search( uuid: string, searchCriteria?: IProfileDisplayDataContractDefinitionSearchCriteria ): Observable<IProfileDisplayDataContractDefinition[]>
  {
    const data = { contract: searchCriteria || {} };

    return this.apiService.post<IContractDefinitionApiResponse>(
      API_ENDPOINT_MAP.contract.definition.post,
      data,
      { uuid: uuid }
    )
      .pipe(
        switchMap( ( response: IContractDefinitionApiResponse ) => {
          this._definitions.next( response.contract );

          if ( searchCriteria && searchCriteria.uuid ) {
            this._definition.next( response.contract.find( ( definition: IProfileDisplayDataContractDefinition ) => {
              return definition.uuid === searchCriteria.uuid;
            } ) );
          }

          return this._definitions;
        } )
      );
  }

  public clearCurrentDefinition(): void
  {
    this._definition.next( null );
  }
}
