<mat-checkbox (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()" class="mat-column-select">
</mat-checkbox>
<button mat-icon-button (click)="refresh()"><mat-icon>refresh</mat-icon></button>
<div class="flex-spacer"></div>
<mat-form-field id="bulkActionPlaceholderField">
  <mat-label>Bulk Actions</mat-label>
  <mat-select disabled></mat-select>
  <mat-hint>Feature not implemented yet</mat-hint>
</mat-form-field>
