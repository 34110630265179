import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationStart,
  Params,
  Router
} from '@angular/router';
import { filter, share, switchMap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { SearchService } from '../../../search/service/search.service';
import { ISearchCriteria } from '../../../search/interfaces.defs';
import { AuthenticationService } from "../../authentication/service/authentication.service";
import { ScopeService } from "../../scope/service/scope.service";
import { AuthorizationService } from "../../authorization/authorization.service";

@Injectable( {
  providedIn: 'root'
} )
export class VisibilityService
{

  private _actionBarVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );
  private _searchFiltersBarVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );
  private _isSearchView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );
  private _isReadOnlyView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );

  constructor(
    private authenticationService: AuthenticationService,
    private authorizationService: AuthorizationService,
    private scopeService: ScopeService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  public determineActionBarVisibility(): void {
    this.router.events
      .pipe(
        filter( ( event: any ) => event instanceof NavigationStart )
      )
      .subscribe( ( event: NavigationStart ) =>
      {
        this._actionBarVisibility.next( false );

        if ( new RegExp( 'search|profile|event', 'g' ).test( event.url ) ) {
          this._actionBarVisibility.next( true );
        }

        this._isReadOnlyView.next( false );
        if ( new RegExp( 'profile-sme', 'g' ).test( event.url ) ) {
          this._isReadOnlyView.next( true );
        }

      } );
  }

  public determineSearchFiltersBarVisibility(): void {
    this.route.queryParams
      .pipe(
        switchMap( ( params: Params ) =>
        {
          if ( params.searchCriteria ) {
            return of( SearchService.deserializeQueryParameters( params.searchCriteria ) );
          }

          return of( {} );
        } ),
        share()
      )
      .subscribe( ( searchCriteria: ISearchCriteria ) =>
      {
        this._searchFiltersBarVisibility.next( false );
        const searchCriteriaKeys = Object.keys( searchCriteria );

        this._searchFiltersBarVisibility.next(
          searchCriteriaKeys
            .filter( searchCriteriaKey => searchCriteria[ searchCriteriaKey ] !== null && searchCriteria[ searchCriteriaKey ] !== '' )
            .length > 0
        );
      } );
  }

  get actionBarVisibility(): BehaviorSubject<boolean> {
    return this._actionBarVisibility;
  }

  get searchCriteriaBarVisibility(): BehaviorSubject<boolean> {
    return this._searchFiltersBarVisibility;
  }

  public determineIfSearchViewIsVisible(): void {
    this.router.events
      .pipe(
        filter( ( event: any ) => event instanceof NavigationStart )
      )
      .subscribe( ( event: NavigationStart ) => {
        let isVisible: boolean = false;

        if ( new RegExp( '^\/$|search', 'g' ).test( event.url ) ) {
          isVisible = true;
        }

        this._isSearchView.next( isVisible );
      });
  }

  get isSearchView(): BehaviorSubject<boolean> {
    return this._isSearchView;
  }

  get singleScopeSelected(): BehaviorSubject<boolean> {
    return this.scopeService.singleScopeSelected;
  }

  get userManageFunctionalityVisibility(): BehaviorSubject<boolean> {
    return this.authorizationService.currentScopeHasUserManagePermission;
  }

  get isProfileReadOnly(): BehaviorSubject<boolean> {
    return this._isReadOnlyView;
  }

  get contractManageFunctionalityVisibility(): BehaviorSubject<boolean> {
    return this.authorizationService.currentScopeHasContractManage;
  }
}
