import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SelectionService } from '../../core/selection/service/selection.service';
import { AttributeService } from '../../profile/service/attribute.service';
import { NotificationService } from '../../shared/notification/notification.service';
import { filter, take } from 'rxjs/operators';
import { IProfileDisplayDataContextAttribute } from '../../search/interfaces.defs';
import { EPageNames } from '../../interfaces.defs';

@Component( {
  selector: 'app-attribute-add',
  templateUrl: './attribute-add.component.html',
  styleUrls: [ '../action-bar.components.scss' ]
} )
export class AttributeAddComponent implements OnInit
{
  private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>( 1 );
  private rootRoute: ActivatedRoute;
  private uuid: string;

  constructor(
    private selectionService: SelectionService,
    private router: Router,
    private notificationService: NotificationService,
    public location: Location,
    public attributeService: AttributeService
  )
  {
  }

  ngOnInit()
  {
    this.rootRoute = this.router.routerState.root.children[0];

    const primaryRoute: ActivatedRoute = this.rootRoute.children.filter( ( route: ActivatedRoute ) => route.outlet === 'primary' )[0];

    this.uuid = primaryRoute.snapshot.paramMap.get( 'uuid' );
  }

  ngOnDestroy(): void
  {
    this.destroyed.next( true );
    this.destroyed.complete();
  }

  public createAttribute(): void
  {
    const form = this.attributeService.form;

    this.attributeService.create( this.uuid, form.controls.contextId.value )
      .pipe(
        take( 1 ),
        filter( attribute => attribute !== null)
      )
      .subscribe( ( attribute: IProfileDisplayDataContextAttribute[] ) => {
        this.notificationService.showSuccessMessage( 'Attribute successfully created.' );

        this.router.navigate( [ EPageNames.PROFILE_EDIT_ATTRIBUTE, this.uuid, form.controls.contextId.value ], { relativeTo: this.rootRoute } );
      } );
  }

  public addAttribute(): void
  {
    this.attributeService.addAttribute.next( true );
  }

  public resetForm(): void
  {
    this.attributeService.resetForm.next( true );
  }
}
