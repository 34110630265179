import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ISearchFilterChip, IChipKeyToValueMap, ISearchCriteria } from '../interfaces.defs';
import { SearchService } from '../service/search.service';
import { VisibilityService } from '../../core/visibility/service/visibility.service';

@Component( {
  selector: 'search-filters',
  templateUrl: './search-filters.component.html',
  styleUrls: [ './search-filters.component.scss' ]
} )
export class SearchFiltersComponent implements OnInit
{
  private chipsKeyToNameMap: IChipKeyToValueMap = {
    'keywords': 'Generic Search Value',
    'username': 'Username',
    'status': 'User Status',
    'firstName': 'First Name',
    'lastName': 'Last Name',
    'contact.address': 'Phone Number / Email',
    'address.postal': 'Zip Code',
    'c.contractDefinitionName': 'Contract Type',
    'c.status': 'Contract Status',
    'additionalAttributes': 'Additional Attributes',
    'contractAttributes': 'Contract Attributes'
  };

  public chipsList: ISearchFilterChip[] = [];

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    public visibilityService: VisibilityService
  )
  {
  }

  ngOnInit()
  {
    this.searchService.getSearchFilters()
      .subscribe( ( searchCriteria: ISearchCriteria ) =>
      {
        const searchCriteriaKeys = Object.keys( searchCriteria );

        this.chipsList = [];

        for ( let i = 0, j = searchCriteriaKeys.length; i < j; i++ ) {
          if ( searchCriteria[ searchCriteriaKeys[ i ] ] ) {
            let name = this.chipsKeyToNameMap[ searchCriteriaKeys[ i ] ];
            name = name ? name : this.determineSpecialName( searchCriteriaKeys[ i ] );

            this.chipsList.push( <ISearchFilterChip>{
              name: name,
              value: searchCriteria[ searchCriteriaKeys[ i ] ],
              key: searchCriteriaKeys[ i ]
            } );
          }
        }
      } );
  }

  determineSpecialName( key: string ): string {
    const contractAttributeKey = 'c.attr.';

    let isContractAttribute = key.includes( contractAttributeKey );
    if ( isContractAttribute ) {
      key = key.replace( contractAttributeKey, '' );
      return `Contract Attribute (${key})`;
    } else {
      const attributeKey = 'attr.';
      key = key.replace( attributeKey, '' );
      return `Attribute (${key})`;
    }
  }

  public removeSearchFilter( chip: ISearchFilterChip ): void
  {
    this.searchService.removeFilter( chip.key );
  }

  clearSearchFilters(): void
  {
    this.searchService.removeAllFilters();
  }

  applyGenericFilter( eventObject: MouseEvent ): void
  {
    const filterNode = <HTMLInputElement> eventObject.target;
    this.searchService.addSearchFilter( filterNode.value );
    filterNode.value = '';
  }
}
