import { Observable } from "rxjs";

function loadJSON(filePath) {
  let json;
  loadTextFileAjaxSync(filePath, 'application/json').subscribe(
    (data: any) => {
      json = data;
    },
    (error: any) => {
      json = error;
    }
  );
  return json;
}

function loadTextFileAjaxSync(filePath, mimeType) {
  const xmlhttp = new XMLHttpRequest();
  xmlhttp.open('GET', filePath, false);
  if (mimeType != null) {
    if (xmlhttp.overrideMimeType) {
      xmlhttp.overrideMimeType(mimeType);
    }
  }
  xmlhttp.send();
  if (xmlhttp.status == 200) {
    // return xmlhttp.responseText;
    return Observable.create((observer) => {
      observer.next(JSON.parse(xmlhttp.responseText));
      observer.complete();
    });
  } else {
    return Observable.create((observer) => {
      const error = {
        noBasic: true,
        status: xmlhttp.status,
        statusText: xmlhttp.statusText,
        html: xmlhttp.responseText
      };
      observer.error(error);
    });
  }
}

/**
 * TODO: create configuration service for this
 */
//const defaultConfigUrl = './api/config/basic.json';
const defaultConfigUrl = './assets/configuration/basic.json';
export const environment = loadJSON(defaultConfigUrl.concat('?noCache=' + Date.now()));
