import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NotificationService } from '../../shared/notification/notification.service';
import { filter, take } from 'rxjs/operators';
import { ContactDetailsService } from '../../profile/service/contact-details.service';
import { EPageNames } from '../../interfaces.defs';

@Component( {
  selector: 'app-contact-details-add',
  templateUrl: './contact-details-add.component.html',
  styleUrls: [ '../action-bar.components.scss' ]
} )
export class ContactDetailsAddComponent implements OnInit
{
  private rootRoute: ActivatedRoute;
  private scope: string;
  private uuid: string;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    public location: Location,
    public contactDetailsService: ContactDetailsService
  )
  {
  }

  ngOnInit()
  {
    this.rootRoute = this.router.routerState.root.children[0];

    const primaryRoute: ActivatedRoute = this.rootRoute.children.filter( ( route: ActivatedRoute ) => route.outlet === 'primary' )[0];

    this.scope = primaryRoute.snapshot.paramMap.get( 'scope' );
    this.uuid = primaryRoute.snapshot.paramMap.get( 'uuid' );
  }

  public createContactDetails(): void
  {
    this.contactDetailsService.create( this.uuid )
      .pipe(
        take( 1 ),
        filter( contactDetail => contactDetail !== null )
      )
      .subscribe( () => {
        this.notificationService.showSuccessMessage( 'Contact details successfully created.' );

        this.router.navigate( [ EPageNames.PROFILE_VIEW, this.scope, this.uuid ], { relativeTo: this.rootRoute } );
      } );
  }

  public resetForm(): void
  {
    this.contactDetailsService.resetForm.next( true );
  }
}
