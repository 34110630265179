import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IAddressApiResponse, IProfileDisplayDataAddress } from '../../search/interfaces.defs';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter, switchMap } from 'rxjs/operators';
import { AddressDeleteConfirmationDialogComponent } from '../delete/address-delete-confirmation-dialog/address-delete-confirmation-dialog.component';
import { API_ENDPOINT_MAP, ApiService } from '../../core/api/api.service';
import { AbstractFormService } from '../../core/form/abstract-form-service';

@Injectable( {
  providedIn: 'root'
} )
export class AddressService extends AbstractFormService
{

  private _address: BehaviorSubject<IProfileDisplayDataAddress> = new BehaviorSubject<IProfileDisplayDataAddress>( null );

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog
  )
  {
    super();
  }

  public get form(): FormGroup
  {
    return this._form;
  }

  public set form( form: FormGroup )
  {
    this._form = form;
  }

  public get address(): IProfileDisplayDataAddress
  {
    return this._address.getValue();
  }

  public set address( address: IProfileDisplayDataAddress )
  {
    this._address.next( address );
  }

  public get( uuid: string, addressUuid: string ): Observable<IProfileDisplayDataAddress>
  {
    return this.apiService.get<IAddressApiResponse>(
      API_ENDPOINT_MAP.address.get,
      { uuid: uuid, addressUuid: addressUuid }
    )
      .pipe(
        switchMap( ( result: IAddressApiResponse) => {
          this._address.next( result.address );

          return this._address;
        } )
      );
  }

  public create( uuid: string ): Observable<IProfileDisplayDataAddress>
  {
    this.triggerFormValidation( this._form );

    if ( !this._form.valid ) {
      return of( null );
    }

    const selectedAddress = this._form.value;

    return this.apiService.post<IAddressApiResponse>(
      API_ENDPOINT_MAP.address.post,
      { address: selectedAddress },
      { uuid: uuid }
    )
      .pipe(
        switchMap( ( result: IAddressApiResponse ) => {
          this._address.next( result.address );
          this._form.markAsPristine();
          return this._address;
        } )
      )
  }

  public update( uuid: string ): Observable<IProfileDisplayDataAddress>
  {
    this.triggerFormValidation( this._form );

    if ( !this._form.valid ) {
      return of( null );
    }

    const data = this._form.value,
      address = this._address.getValue()
    ;

    return this.apiService.patch<IAddressApiResponse>(
      API_ENDPOINT_MAP.address.patch,
      { address: data },
      { uuid: uuid, addressUuid: address.uuid }
    )
      .pipe(
        switchMap( ( result: IAddressApiResponse ) => {

          this._address.next( result.address );
          this._form.markAsPristine();
          return this._address;
        } )
      );
  }

  public delete( uuid: string, addressUuid: string ): Observable<IProfileDisplayDataAddress>
  {
    const confirmedAction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false ),
      dialogRef = this.dialog.open( AddressDeleteConfirmationDialogComponent )
    ;

    dialogRef.afterClosed()
      .subscribe( confirmation => {
        confirmedAction.next( confirmation );
        confirmedAction.complete();
      } );

    return confirmedAction
      .pipe(
        filter( confirmation => confirmation ),
        switchMap( () => {
          return this.apiService.delete<IAddressApiResponse>(
            API_ENDPOINT_MAP.address.delete,
            { uuid: uuid, addressUuid: addressUuid }
          )
            .pipe(
              switchMap( ( result: IAddressApiResponse ) => {
                this._address.next( null );
                return this._address;
              } )
            )
        } )
      );
  }
}
