export interface IContainerState {
  localStorageTokenKey: string;
}

export class IFrameContainerStrategy implements IContainerState
{
  public localStorageTokenKey = 'IDToken_iframe';
}

export class ParentContainerStrategy implements IContainerState
{
  public localStorageTokenKey = 'IDToken';
}
