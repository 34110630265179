import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['../action-bar.components.scss']
})
export class EventComponent implements OnInit {

  private rootRoute: ActivatedRoute;

  constructor(
    private router: Router) { }

  ngOnInit(): void {
    this.rootRoute = this.router.routerState.root.children[0];
    const primaryRoute: ActivatedRoute = this.rootRoute.children.filter( ( route: ActivatedRoute ) => route.outlet === 'primary' )[0];
  }

}
