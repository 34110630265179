import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IApiResponse, IApiResponseError, IApiResponseErrorCollection, IApiResponseErrorHandler } from '../../interfaces.defs';

export interface IHttpOptions
{
  headers?: HttpHeaders | {
    [param: string]: string | string[];
  };
  observe?: 'body';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export interface IApiEndpointParameters
{
  [key: string]: string | number;
}

export const API_ENDPOINT_MAP = {
  authentication: {
    _prefix: '/authentication',
    custLogin: "/cust/getToken",
    keycloakLogin: "/keycloak/getToken",
    validate: "/validateToken",
    scope: "/getScopes"
  },
  scope: {
    get: '/scope/translations?language=:languageCode'
  },
  scopeConfiguration: {
    get: '/scopes/configuration?ids=:scope'
  },
  search: {
    user: {
      get: '/search/users',
      post: '/search/v2/users',
    },
    contract: {
      get: '/search/contracts'
    }
  },
  profile: {
    get: '/:uuid',
    patch: '/:uuid/update',
    post: '',
    statusRemoved: {
      get: '/:uuid/remove'
    },
    memberData: {
      get: '/memberData/:uuid'
    },
    contracts: {
      get: '/contracts/:uuid'
    }
  },
  contactDetails: {
    post: '/:uuid/contactDetails',
    delete: '/:uuid/contactDetails?address=:addressUuid',
    setPreferred: {
      get: '/:uuid/preferContactDetail?address=:addressUuid'
    }
  },
  address: {
    get: '/:uuid/address/:addressUuid',
    patch: '/:uuid/address/:addressUuid',
    post: '/:uuid/address',
    delete: '/:uuid/address/:addressUuid'
  },
  contract: {
    get: '/:uuid/contract',
    put: '/:uuid/contract',
    post: '/:uuid/contract/create',
    delete: '/:uuid/contract',
    definition: {
      post: '/:uuid/contract/definition/search'
    }
  },
  attribute: {
    get: '/:uuid/context/:contextId/attribute',
    put: '/:uuid/context/:contextId/attribute',
    post: '/:uuid/context',
    delete: '/:uuid/context/:contextId'
  },
  linking: {
    get: '/linking/:uuid/:scope'
  },
  event: {
    get: '/history/:uuid',
    post: '/history/:uuid/comment'
  },
  vehicle: {
    get: '/vehicleData/:vin'
  },
  vehicleTripSummary: {
    post: '/p2pTripSummaryData'
  }
};

export class ApiError extends Error
{
  public code: string;

  constructor( error: IApiResponseError )
  {
    super( error.message );
    this.code = error.code.toString();
  }
}

@Injectable( {
  providedIn: 'root'
} )
export class ApiService
{
  private readonly API_PREFIX = '/api/userData';

  constructor(
    private http: HttpClient
  )
  {
  }

  public get<T extends IApiResponse>( url: string, parameters?: IApiEndpointParameters, options?: IHttpOptions ): Observable<T>
  {
    let parsedUrl: string = url;
    if ( parameters ) {
      let queryParameters: string[] = [];
      Object.keys( parameters ).forEach( ( parameterName ) => {
        if ( !url.includes(parameterName) ) {
          queryParameters.push(parameterName);
        }
      } );

      if ( queryParameters.length > 0 ) {
        parsedUrl += '?' + queryParameters[0] + '=:' + queryParameters[0];
        for ( let i = 1; i < queryParameters.length; i++ ) {
          parsedUrl += '&' + queryParameters[i] + '=:' + queryParameters[i];
        }
      }
    }

    return this.genericHttpMethodHandler<T>( 'get', parsedUrl, undefined, parameters, options );
  }

  public patch<T extends IApiResponse>( url: string, body: any, parameters?: IApiEndpointParameters, options?: IHttpOptions, errorHandler?: IApiResponseErrorHandler ): Observable<T>
  {
    return this.genericHttpMethodHandler<T>( 'patch', url, body, parameters, options, errorHandler );
  }

  public post<T extends IApiResponse>( url: string, body: any, parameters?: IApiEndpointParameters, options?: IHttpOptions, errorHandler?: IApiResponseErrorHandler ): Observable<T>
  {
    return this.genericHttpMethodHandler<T>( 'post', url, body, parameters, options, errorHandler );
  }

  public put<T extends IApiResponse>( url: string, body: any, parameters?: IApiEndpointParameters, options?: IHttpOptions ): Observable<T>
  {
    return this.genericHttpMethodHandler<T>( 'put', url, body, parameters, options );
  }

  public delete<T extends IApiResponse>( url: string, parameters?: IApiEndpointParameters, options?: IHttpOptions ): Observable<T>
  {
    return this.genericHttpMethodHandler<T>( 'delete', url, undefined, parameters, options );
  }

  private genericHttpMethodHandler<T extends IApiResponse>(
    methodName: string,
    url: string,
    body?: any,
    parameters?: IApiEndpointParameters,
    options?: IHttpOptions,
    errorHandler?: IApiResponseErrorHandler
  ): Observable<T>
  {
    let parsedUrl = url;

    if ( !url.match( new RegExp( API_ENDPOINT_MAP.authentication._prefix ) ) ) {
      parsedUrl = this.API_PREFIX + url;
    }

    if ( parameters ) {
      parsedUrl = this.parseUrl( parsedUrl, parameters );
    }

    return this.http[methodName]<T>( parsedUrl, body, options ).pipe(
      tap( ( data: T ) => {
        Object.keys( data.errors ).length && this.handleError( data.errors, errorHandler );
      } )
    );
  }

  private handleError( errors: IApiResponseErrorCollection, additionalErrorHandler?: IApiResponseErrorHandler ): void
  {
    let error = '';

    Object.keys( errors ).forEach( ( serviceName: string ) => {
      error += `<h5>Service name: ${ serviceName }</h5>`;
      error += `<strong>Message</strong>: ${ errors[serviceName].message }<br>`;
      error += `<strong>Code</strong>: ${ errors[serviceName].code }<br><br>`;

      additionalErrorHandler && additionalErrorHandler( errors[serviceName] );
    } );

    if ( error !== '' ) {
      throw new ApiError( {
        message: error,
        code: 'backend_error'
      } );
    }
  }

  private parseUrl( url: string, parameters: IApiEndpointParameters ): string
  {
    let parsedUrl = url;

    Object.keys( parameters ).forEach( ( parameterName ) => {
      parsedUrl = parsedUrl.replace( `:${parameterName}`, encodeURIComponent( parameters[parameterName].toString() ) );
    } );

    return parsedUrl;
  }
}
