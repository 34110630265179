import { Injectable } from '@angular/core';
import { IProfileSearchApiResponse, ISearchCriteria, ISearchResultItem } from '../interfaces.defs';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { API_ENDPOINT_MAP, ApiService } from '../../core/api/api.service';
import { ScopeService } from "../../core/scope/service/scope.service";
import { NotificationService } from "../../shared/notification/notification.service";

@Injectable( {
  providedIn: 'root'
} )
export class SearchResultsService
{
  private _searchResults: BehaviorSubject<ISearchResultItem[]> = new BehaviorSubject<ISearchResultItem[]>( null );
  private _searchResultsLength: number = 0;
  public isDataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );

  constructor(
    private apiService: ApiService,
    private scopeService: ScopeService,
    private notificationService: NotificationService
  ) { }

  public search( searchCriteria: ISearchCriteria ): Observable<ISearchResultItem[]> {
    let selectedScopes = this.scopeService.currentScopeSelection.getValue();
    if ( selectedScopes.length === 0 ) {
      this._searchResults.next( null );
      this._searchResultsLength = 0;
      this.isDataLoaded.next( true );
      this.notificationService.showErrorMessage( { errorMessage: "No scope selected.", errorCode: "search" } );
      return of(null);
    }

    this.isDataLoaded.next( false );
    let scopes = selectedScopes[0];
    for (let i = 1; i < selectedScopes.length; i++) {
      scopes += `,${selectedScopes[i]}`;
    }
    searchCriteria.scope = scopes;

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.apiService.get<IProfileSearchApiResponse>( API_ENDPOINT_MAP.search.user.get, searchCriteria, httpOptions )
      .pipe(
        switchMap( ( data: IProfileSearchApiResponse ) => {
          this._searchResults.next( data.search.items );
          this._searchResultsLength = data.search.total;
          this.isDataLoaded.next( true );

          return this._searchResults;
        } )
      );
  }

  public get searchResults(): ISearchResultItem[]
  {
    return this._searchResults.getValue();
  }

  public get searchResultsLength(): number
  {
    return this._searchResultsLength;
  }
}
