import { ErrorHandler, Inject, Injectable, Injector, NgZone } from '@angular/core';
import { NotificationService } from "../shared/notification/notification.service";
import { ApiError } from './api/api.service';

@Injectable()
export class SnackBarErrorHandler implements ErrorHandler
{
  constructor(
    @Inject( Injector ) private readonly injector: Injector
  )
  {
  }

  handleError( error: any ): void
  {
    let formattedError = {
      errorMessage: 'Application Error. Please try again later.',
      errorCode: '0'
    };

    if ( error instanceof ErrorEvent ) {
      formattedError = {
        errorMessage: 'Application Error. Please try again later.',
        errorCode: '0'
      };
    }

    if ( error.message ) {
      formattedError.errorMessage = error.message;
    }

    if ( error instanceof ApiError ) {
      formattedError.errorMessage = error.message;
      formattedError.errorCode = error.code;
    }

    if ( error.error && error.error.message && error.error.code ) {
      let errorDetails = error.error;

      if ( error.error.details && error.error.details.length > 0 ) {
        errorDetails = error.error.details[0];
      }

      formattedError.errorMessage = errorDetails.message;
      formattedError.errorCode = errorDetails.code;
    }

    const zone = this.injector.get( NgZone ),
      notificationService = this.injector.get( NotificationService )
    ;

    zone.run( () =>
    {
      notificationService.showErrorMessage( formattedError );
    } );
  }

}
