import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ODMHttpInterceptor } from './core/http-interceptor';
import { StorageModule } from '@ngx-pwa/local-storage';
import { SnackBarErrorHandler } from './core/error-handler';
import { AppRoutingModule } from './app-routing.module';

import { HomeTemplateComponent } from './home/display/home-template.component';
import { DashboardComponent } from './home/dashboard/dashboard.component';

import { TopBarModule } from './top-bar/top-bar.module';
import { SearchModule } from './search/search.module';
import { ActionBarModule } from './action-bar/action-bar.module';
import { OverlayModule } from '@angular/cdk/overlay';
import { ExportDataComponent } from './export-data/export-data.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule( {
  declarations: [
    AppComponent,
    HomeTemplateComponent,
    DashboardComponent,
    ExportDataComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    HttpClientModule,
    StorageModule.forRoot( { IDBNoWrap: true } ),
    OverlayModule,
    TopBarModule,
    SearchModule,
    ActionBarModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ODMHttpInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: SnackBarErrorHandler
    }
  ],
  bootstrap: [ AppComponent ]
} )
export class AppModule
{
}
