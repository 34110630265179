<div class="flex-spacer"></div>
<button mat-raised-button color="primary" (click)="editAttribute()"
        *ngIf="visibilityService.userManageFunctionalityVisibility | async">
  <mat-icon>edit</mat-icon>Edit Attribute Group
</button>
<button mat-raised-button color="warn" (click)="deleteAttribute()"
        *ngIf="visibilityService.userManageFunctionalityVisibility | async">
  <mat-icon>delete</mat-icon>Delete Attribute Group
</button>

<app-profile-view></app-profile-view>
