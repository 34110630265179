<mat-form-field>
  <label>
    <input matInput type="text" placeholder="You can use any search term here" [formControl]="searchTextControl" (keydown.enter)="genericSearch()" (focus)="isAdvancedSearchOpen = false">
  </label>
  <button mat-button mat-icon-button matSuffix aria-label="Clear" (click)="clearSearchValue()" *ngIf="searchTextControl.value">
    <mat-icon>close</mat-icon>
  </button>
  <button mat-button mat-icon-button matSuffix aria-label="Clear" (click)="toggleAdvancedSearchVisibility( $event )">
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</mat-form-field>
<mat-card @toggleAdvancedSearchVisibility *ngIf="isAdvancedSearchOpen">
  <mat-card-header>
    <mat-card-title>Advanced Search</mat-card-title>
    <mat-card-subtitle>Use this form to search for specific user attributes</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form id="advancedSearchForm" [formGroup]="advancedFormGroup" (ngSubmit)="advancedSearch()">
      <mat-form-field>
        <label>
          <input matInput placeholder="Username" formControlName="username">
        </label>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select disableOptionCentering formControlName="status">
          <mat-option>Clear Selection</mat-option>
          <mat-option value="active">active</mat-option>
          <mat-option value="inactive">inactive</mat-option>
          <mat-option value="locked">locked</mat-option>
          <mat-option value="removed">removed</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <label>
          <input matInput placeholder="First Name" formControlName="firstName">
        </label>
      </mat-form-field>
      <mat-form-field>
        <label>
          <input matInput placeholder="Last Name" formControlName="lastName">
        </label>
      </mat-form-field>
      <mat-form-field>
        <label>
          <input matInput placeholder="Phone Number / Email" formControlName="contact.address">
        </label>
      </mat-form-field>
      <mat-form-field>
        <label>
          <input matInput placeholder="Zip" formControlName="address.postal">
        </label>
      </mat-form-field>

      <mat-form-field>
        <label>
          <input matInput placeholder="Contract type" formControlName="c.contractDefinitionName">
        </label>
      </mat-form-field>

      <mat-form-field>
        <label>
          <input matInput placeholder="Contract Status" formControlName="c.status">
        </label>
      </mat-form-field>

      <div>
        <mat-label>Attributes</mat-label>
        <mat-form-field>
          <label>
            <input #group matInput placeholder="Group">
          </label>
        </mat-form-field>
        <mat-form-field>
          <label>
            <input #attributeKey matInput placeholder="Key">
          </label>
        </mat-form-field>
        <mat-form-field>
          <label>
            <input #attributeValue matInput placeholder="Value">
          </label>
        </mat-form-field>
        <button mat-raised-button color="primary" type="button" (click)="searchService.addAttribute(group.value, attributeKey.value, attributeValue.value)">Add</button>
      </div>

      <div>
        <mat-chip-list [selectable]="false">
          <mat-chip *ngFor="let chip of searchService.attributes" removable (removed)="searchService.removeAttribute( chip )">
            {{chip.name}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <mat-chip *ngIf="searchService.attributes.length" aria-label="Clear" (click)="searchService.removeAllAttributes()" class="clearFiltersChip" [disableRipple]="true">Clear</mat-chip>
        </mat-chip-list>
      </div>

      <div>
        <mat-label>Contract Attributes</mat-label>
        <mat-form-field>
          <label>
            <input #contractAttributeKey matInput placeholder="Key">
          </label>
        </mat-form-field>
        <mat-form-field>
          <label>
            <input #contractAttributeValue matInput placeholder="Value">
          </label>
        </mat-form-field>
        <button mat-raised-button color="primary" type="button" (click)="searchService.addContractAttribute(contractAttributeKey.value, contractAttributeValue.value)">Add</button>
      </div>

      <div>
        <mat-chip-list [selectable]="false">
          <mat-chip *ngFor="let chip of searchService.contractAttributes" removable (removed)="searchService.removeContractAttribute( chip )">
            {{chip.name}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <mat-chip *ngIf="searchService.contractAttributes.length" aria-label="Clear" (click)="searchService.removeAllContractAttributes()" class="clearFiltersChip" [disableRipple]="true">Clear</mat-chip>
        </mat-chip-list>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" type="submit" form="advancedSearchForm">Search</button>
  </mat-card-actions>
</mat-card>
