import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AddressService } from '../../profile/service/address.service';
import { NotificationService } from '../../shared/notification/notification.service';
import { filter, take } from 'rxjs/operators';
import { EPageNames } from '../../interfaces.defs';
import { IProfileDisplayDataAddress } from '../../search/interfaces.defs';

@Component( {
  selector: 'app-address-add',
  templateUrl: './address-add.component.html',
  styleUrls: [ '../action-bar.components.scss' ]
} )
export class AddressAddComponent implements OnInit
{
  private rootRoute: ActivatedRoute;
  private uuid: string;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    public location: Location,
    public addressService: AddressService
  )
  {
  }

  ngOnInit()
  {
    this.rootRoute = this.router.routerState.root.children[0];

    const primaryRoute: ActivatedRoute = this.rootRoute.children.filter( ( route: ActivatedRoute ) => route.outlet === 'primary' )[0];

    this.uuid = primaryRoute.snapshot.paramMap.get( 'uuid' );
  }

  public createAddress(): void
  {
    this.addressService.create( this.uuid )
      .pipe(
        take( 1 ),
        filter( address => address !== null)
      )
      .subscribe( ( address: IProfileDisplayDataAddress ) => {
        this.notificationService.showSuccessMessage( 'Address successfully created.' );

        this.router.navigate( [ EPageNames.PROFILE_EDIT_ADDRESS, this.uuid, address.uuid ], { relativeTo: this.rootRoute } );
      } );
  }

  public resetForm(): void
  {
    this.addressService.resetForm.next( true );
  }
}
