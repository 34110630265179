<div class="flex-spacer"></div>
<button mat-raised-button color="primary" (click)="editContract()"
        *ngIf="visibilityService.contractManageFunctionalityVisibility | async">
  <mat-icon>edit</mat-icon>Edit Contract
</button>
<button mat-raised-button color="warn" (click)="deleteContract()"
        *ngIf="visibilityService.contractManageFunctionalityVisibility | async">
  <mat-icon>delete</mat-icon>Delete Contract
</button>

<app-profile-view></app-profile-view>
