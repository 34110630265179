import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  IContactDetailsApiResponse,
  IProfileDisplayDataContactDetail
} from '../../search/interfaces.defs';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { filter, switchMap } from 'rxjs/operators';
import { ContactDetailsDeleteConfirmationDialogComponent } from '../delete/contact-details-delete-confirmation-dialog/contact-details-delete-confirmation-dialog.component';
import { PreferredContactDetailsConfirmationDialogComponent } from '../edit/contact-details/preferred-contact-details-confirmation-dialog/preferred-contact-details-confirmation-dialog.component';
import { API_ENDPOINT_MAP, ApiService } from '../../core/api/api.service';
import { AbstractFormService } from '../../core/form/abstract-form-service';

@Injectable( {
  providedIn: 'root'
} )
export class ContactDetailsService extends AbstractFormService
{
  private _contactDetails: BehaviorSubject<IProfileDisplayDataContactDetail> = new BehaviorSubject<IProfileDisplayDataContactDetail>( null );

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog
  )
  {
    super();
  }

  public get form(): FormGroup
  {
    return this._form;
  }

  public set form( form: FormGroup )
  {
    this._form = form;
  }

  public get contactDetails(): IProfileDisplayDataContactDetail
  {
    return this._contactDetails.getValue();
  }

  public set contactDetails( attribute: IProfileDisplayDataContactDetail )
  {
    this._contactDetails.next( attribute );
  }

  public create( uuid: string ): Observable<IProfileDisplayDataContactDetail>
  {
    this.triggerFormValidation( this._form );

    if ( !this._form.valid ) {
      return of( null );
    }

    return this.apiService.post<IContactDetailsApiResponse>(
      API_ENDPOINT_MAP.contactDetails.post,
      {
        contactDetails: {
          address: this._form.get( 'address' ).value,
          type: this._form.get( 'type' ).value
        }
      },
      { uuid: uuid }, undefined, this.errorHandler.bind(this)
    )
      .pipe(
        switchMap( ( result: IContactDetailsApiResponse ) => {
          this._contactDetails.next( result.contactDetails );
          this._form.markAsPristine();
          return this._contactDetails;
        } )
      )
  }

  public delete( uuid: string, contactDetailsAddress: string ): Observable<IProfileDisplayDataContactDetail>
  {
    const confirmedAction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false ),
      dialogRef = this.dialog.open( ContactDetailsDeleteConfirmationDialogComponent )
    ;

    dialogRef.afterClosed()
      .subscribe( confirmation => {
        confirmedAction.next( confirmation );
        confirmedAction.complete();
      } );

    return confirmedAction
      .pipe(
        filter( confirmation => confirmation ),
        switchMap( () => {
          return this.apiService.delete<IContactDetailsApiResponse>(
            API_ENDPOINT_MAP.contactDetails.delete,
            { uuid: uuid, addressUuid: contactDetailsAddress }
          )
            .pipe(
              switchMap( ( result: IContactDetailsApiResponse ) => {
                this._contactDetails.next( null );
                return this._contactDetails;
              } )
            )
        } )
      );
  }

  setPreferred( uuid: string, contactDetailsAddress: string ): Observable<IProfileDisplayDataContactDetail>
  {
    const confirmedAction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false ),
      dialogRef = this.dialog.open( PreferredContactDetailsConfirmationDialogComponent )
    ;

    dialogRef.afterClosed()
      .subscribe( confirmation => {
        confirmedAction.next( confirmation );
        confirmedAction.complete();
      } );

    return confirmedAction
      .pipe(
        filter( confirmation => confirmation ),
        switchMap( () => {
          return this.apiService.get<IContactDetailsApiResponse>(
            API_ENDPOINT_MAP.contactDetails.setPreferred.get,
            { uuid: uuid, addressUuid: contactDetailsAddress }
          )
            .pipe(
              switchMap( ( result: IContactDetailsApiResponse ) => {
                this._contactDetails.next( result.contactDetails );
                return this._contactDetails;
              } )
            )
        } )
      );
  }
}
