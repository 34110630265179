import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../shared/shared.module";

import { SearchModule } from "../search/search.module";
import { AppTopBarComponent } from './display/app-top-bar.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { CoreModule } from '../core/core.module';


@NgModule( {
  declarations: [
    AppTopBarComponent,
    UserMenuComponent,
  ],
  exports: [
    AppTopBarComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    SearchModule,

  ]
})
export class TopBarModule { }
