import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationDisplayComponent } from './authentication/display/authentication-display.component';
import { SharedModule } from "../shared/shared.module";
import { ScopeDisplayComponent } from './scope/display/scope-display.component';
import { FormsModule } from '@angular/forms';
import { GenericFormControlComponent } from './form/control/generic-form-control.component';
import { ScopeChangeConfirmationDialogComponent } from './scope/scope-change-confirmation-dialog/scope-change-confirmation-dialog.component';
import { GenericFormComponent } from './form/display/generic-form.component';


@NgModule({
  declarations: [
    AuthenticationDisplayComponent,
    ScopeDisplayComponent,
    GenericFormControlComponent,
    ScopeChangeConfirmationDialogComponent,
    GenericFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ],
  exports: [
    ScopeDisplayComponent,
    GenericFormControlComponent,
    GenericFormComponent
  ]
})
export class CoreModule { }
