<mat-card>
  <mat-card-header>
    <mat-card-title>Member Login</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-tab-group (selectedIndexChange)="tabGroupSelectedIndex = $event">
      <mat-tab label="ODM CUST">
        <form id="odmAuthenticationForm" [formGroup]="odmAuthenticationForm" (ngSubmit)="login( 'odm' )">
          <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username">
            <mat-error *ngIf="odmAuthenticationForm.controls.username.hasError( 'required' )">Username is required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password">
            <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
              <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="odmAuthenticationForm.controls.password.hasError( 'required' )">Password is required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Scope</mat-label>
            <input matInput placeholder="Scope" formControlName="scope">
            <mat-error *ngIf="odmAuthenticationForm.controls.scope.hasError( 'required' )">Scope is required</mat-error>
          </mat-form-field>
        </form>
      </mat-tab>
      <mat-tab label="Keycloak">
        <form id="keycloakAuthenticationForm" [formGroup]="keycloakAuthenticationForm" (ngSubmit)="login( 'keycloak' )">
          <mat-form-field appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username">
            <mat-error *ngIf="keycloakAuthenticationForm.controls.username.hasError( 'required' )">Username is required</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="password">
            <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
              <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
            </button>
            <mat-error *ngIf="keycloakAuthenticationForm.controls.password.hasError( 'required' )">Password is required</mat-error>
          </mat-form-field>
        </form>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" form="odmAuthenticationForm" *ngIf="tabGroupSelectedIndex === 0">Sign in</button>
    <button mat-raised-button color="primary" form="keycloakAuthenticationForm" *ngIf="tabGroupSelectedIndex === 1">Sign in</button>
    <!--<a mat-button href="https://jira.bmw-carsharing.de/secure/ForgotLoginDetails.jspa">Forgot password?</a>-->
  </mat-card-actions>
</mat-card>
