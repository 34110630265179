import { Injectable } from '@angular/core';
import {
  IContractResponse,
  IProfileDataResponse,
  IProfileDisplayData,
  IProfileDisplayDataAddress,
  IProfileDisplayDataContactDetail,
  IProfileDisplayDataContext,
  IProfileDisplayDataContract, IProfileResponse
} from '../../search/interfaces.defs';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, flatMap, switchMap, take } from 'rxjs/operators';
import { AbstractControl, FormGroup } from '@angular/forms';
import { EOutletNames, EPageNames } from '../../interfaces.defs';
import { SelectionService } from '../../core/selection/service/selection.service';
import { ESelectionName } from '../../core/selection/interfaces.defs';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { DATE_FORMATS } from '../../shared/date-picker/date-picker.module';
import { API_ENDPOINT_MAP, ApiService } from '../../core/api/api.service';
import { AbstractFormService } from '../../core/form/abstract-form-service';
import { AuthorizationService } from 'src/app/core/authorization/authorization.service';

@Injectable( {
  providedIn: 'root'
} )

export class ProfileService extends AbstractFormService
{
  private _profile: BehaviorSubject<IProfileDisplayData> = new BehaviorSubject<IProfileDisplayData>( null );

  constructor(
    private apiService: ApiService,
    private authorizationService: AuthorizationService,
    private selectionService: SelectionService,
    private router: Router,
    private route: ActivatedRoute
  )
  {
    super();
  }

  public get profile(): IProfileDisplayData
  {
    return this._profile.getValue();
  }

  public get form(): FormGroup
  {
    return this._form;
  }

  public set form( form: FormGroup )
  {
    this._form = form;
  }

  public get( uuid: string ): Observable<IProfileDisplayData>
  {
    return this.apiService.get<IProfileResponse>( API_ENDPOINT_MAP.profile.get, { uuid: uuid } )
      .pipe(
        switchMap( ( profileResponse: IProfileResponse ) => {
          let profile: IProfileDisplayData = { errors: profileResponse.errors };
          profile.user = profileResponse.user;
          profile.attribute = profileResponse.attribute;

          let member = profileResponse.member;
          if ( member && member.length > 0 ) {
            profile.member = member[0];
            this.apiService.get<IProfileDataResponse>( API_ENDPOINT_MAP.profile.memberData.get, { uuid: uuid } )
              .subscribe(( profileData: IProfileDataResponse) => {
                profile.address = profileData.address;
                profile.contactDetails = profileData.contactDetails;
                this.loadContracts(uuid, profile);
              });
          } else {
            this.loadContracts(uuid, profile);
          }

          return this._profile;
        } )
      );
  }

  private loadContracts(uuid: string, profile: IProfileDisplayData) {
    if (this.authorizationService.currentScopeHasContractView.getValue()) {
      this.apiService.get<IContractResponse>( API_ENDPOINT_MAP.profile.contracts.get, { uuid: uuid } )
        .subscribe(( contractResponse: IContractResponse) => {
          profile.contract = contractResponse.contract;
          this._profile.next( profile );
        });
    } else {
      this._profile.next( profile );
    }
  }

  public createMember(): Observable<IProfileDisplayData>
  {
    const formControls = this._form.controls,
      preferredLanguage = formControls.preferredLanguage.value,
      data = { member: {
        firstName: formControls.firstName.value,
        lastName: formControls.lastName.value,
        userUuid: formControls.userUuid.value,
        birthDate: formControls.birthDate.value
          ? formatDate( formControls.birthDate.value, DATE_FORMATS.parse.dateInput, 'en-US' )
          : formControls.birthDate.value,
        preferredLanguage: preferredLanguage ? preferredLanguage : null,
        contactDetails: [ {
          address: formControls.address.value,
          type: formControls.contactType.value
        } ]
      } }
    ;

    return this.apiService.post<IProfileDisplayData>( API_ENDPOINT_MAP.profile.post, data )
      .pipe(
        switchMap( () => {
          this._form.markAsPristine();
          return this._profile;
        } )
      );
  }

  public create(): Observable<IProfileDisplayData>
  {
    this.triggerFormValidation( this._form );

    if ( !this._form.valid ) {
      return of( null );
    }

    const formControls = this._form.controls,
      preferredLanguage = formControls.preferredLanguage.value,
      data = {
        user: {
          username: formControls.username.value
        },
        member: {
          firstName: formControls.firstName.value,
          lastName: formControls.lastName.value,
          birthDate: formControls.birthDate.value
            ? formatDate( formControls.birthDate.value, DATE_FORMATS.parse.dateInput, 'en-US' )
            : formControls.birthDate.value,
          preferredLanguage: preferredLanguage ? preferredLanguage : null,
          contactDetails: [ {
            address: formControls.address.value,
            type: formControls.contactType.value
          } ]
        }
      }
    ;

    return this.apiService.post<IProfileDisplayData>( API_ENDPOINT_MAP.profile.post, data )
      .pipe(
        switchMap( ( data: IProfileDisplayData ) => {
          this._form.markAsPristine();

          this._profile.next( {
            user: data.user,
            member: data.member,
            errors: {}
          } );

          return this._profile;
        } )
      );
  }

  public update( uuid: string ): Observable<IProfileDisplayData>
  {
    this.triggerFormValidation( this._form );

    if ( !this._form.valid ) {
      return of( null );
    }

    const data = {
        user: {},
        member: {}
      },
      formControls = this._form.controls
    ;

    if ( formControls.status.dirty ) {
      const status: string = formControls.status.value;
      if ( status === 'removed' ) {
        return this.handleStatusRemoved(uuid).pipe(
          flatMap( () => {
            return this.updateProfile(formControls, uuid, data);
          } ));
      } else {
        data.user['status'] = status;
      }
    }

    return this.updateProfile(formControls, uuid, data);
  }

  private updateProfile(
    formControls: { [key: string]: AbstractControl },
    uuid: string,
    data: { user: {}, member: {} }
    ): Observable<IProfileDisplayData> {

    Object.entries( formControls )
      .filter( ( [controlName, control] ) => control.dirty && controlName !== 'status' )
      .forEach( ( [controlName, control] ) => {
        if ( controlName === 'birthDate' ) {
          data.member[ controlName ] = formatDate( control.value, DATE_FORMATS.parse.dateInput, 'en-US' );
        } else {
          data.member[ controlName ] = control.value;
        }
      } );

    return this.apiService.patch<IProfileDisplayData>(  API_ENDPOINT_MAP.profile.patch, data, { uuid: uuid } )
      .pipe(
        switchMap( ( data: IProfileDisplayData ) => {
          const profile = this._profile.getValue();

          for ( let propertyName in data.member ) {
            if ( data.member.hasOwnProperty( propertyName ) ) {
              profile.member[propertyName] = data.member[propertyName];
            }
          }

          this._profile.next( profile );
          this._form.markAsPristine();

          return this._profile;
        } )
      );
  }

  private handleStatusRemoved(uuid: string): Observable<IProfileDisplayData> {
    return this.apiService.get<IProfileDisplayData>(  API_ENDPOINT_MAP.profile.statusRemoved.get, { uuid: uuid } )
      .pipe(
        take( 1 ),
        filter( response => response !== null)
      )
  }

  public showProfileButtons(): void {
    const rootRoute: ActivatedRoute = this.route.children[0];
    this.resetSelection();

    this.router.navigate( [{
      outlets: {
        'action-bar': [EOutletNames.ACTION_BAR, EPageNames.ACTION_BAR_PROFILE_VIEW]
      }
    }], { relativeTo: rootRoute, queryParamsHandling: 'preserve', skipLocationChange: true } );
  }

  private resetSelection(): void {
    const profileSelection =
      this.selectionService.getSelectionCollection<IProfileDisplayDataContactDetail | IProfileDisplayDataAddress | IProfileDisplayDataContract | IProfileDisplayDataContext>( ESelectionName.CUSTOMER_DISPLAY_DATA_SELECTION );
    profileSelection && profileSelection.clear();
  }

  public refresh( uuid: string ): void
  {
    this.get( uuid )
      .pipe(
        filter( profile => profile !== null ),
        take( 1 )
      )
      .subscribe();
  }
}
