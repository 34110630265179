import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  IContextApiResponse,
  IContextAttributeEntryApiResponse,
  IProfileDisplayDataContextAttribute
} from '../../search/interfaces.defs';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AttributeDeleteConfirmationDialogComponent } from '../delete/attribute-delete-confirmation-dialog/attribute-delete-confirmation-dialog.component';
import { ContextDeleteConfirmationDialogComponent } from '../delete/context-delete-confirmation-dialog/context-delete-confirmation-dialog.component';
import { API_ENDPOINT_MAP, ApiService } from '../../core/api/api.service';
import { AbstractFormService } from '../../core/form/abstract-form-service';

@Injectable( {
  providedIn: 'root'
} )
export class AttributeService extends AbstractFormService
{
  private _attribute: BehaviorSubject<IProfileDisplayDataContextAttribute[]> = new BehaviorSubject<IProfileDisplayDataContextAttribute[]>( null );

  public addAttribute: Subject<boolean> = new Subject<boolean>();

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog
  )
  {
    super();
  }

  public get( uuid: string, contextId: string ): Observable<IProfileDisplayDataContextAttribute[]>
  {
    return this.apiService.get(
      API_ENDPOINT_MAP.attribute.get,
      { uuid: uuid, contextId: contextId }
    )
      .pipe(
        switchMap( ( result: IContextAttributeEntryApiResponse ) => {
          this._attribute.next( result.attribute );

          return this._attribute;
        } )
      );
  }

  public get form(): FormGroup
  {
    return this._form;
  }

  public set form( form: FormGroup )
  {
    this._form = form;
  }

  public get attribute(): IProfileDisplayDataContextAttribute[]
  {
    return this._attribute.getValue();
  }

  public set attribute( attribute: IProfileDisplayDataContextAttribute[] )
  {
    this._attribute.next( attribute );
  }

  public create( uuid: string, contextId: string ): Observable<IProfileDisplayDataContextAttribute[]>
  {
    this.triggerFormValidation( this._form );

    if ( !this._form.valid ) {
      return of( null );
    }

    const attributesGroup: FormGroup = <FormGroup>this._form.get( 'additionalAttributes' ),
      attributes = Object.keys( attributesGroup.controls ).map( ( controlName: string ) => {
        return {
          attributeId: controlName,
          attributeValue: attributesGroup.get( controlName ).value
        };
      } );

    return this.apiService.post<IContextApiResponse>(
      API_ENDPOINT_MAP.attribute.post,
      { attribute: {
        contextId: contextId,
        attributes: attributes
      } },
      { uuid: uuid }
    )
      .pipe(
        switchMap( ( result: IContextApiResponse ) => {
          this._attribute.next( result.attribute.attributes );
          this._form.markAsPristine();
          return this._attribute;
        } )
      )
  }

  public update( uuid: string, contextId: string ): Observable<IProfileDisplayDataContextAttribute[]>
  {
    this.triggerFormValidation( this._form );

    if ( !this._form.valid ) {
      return of( null );
    }

    const data: IProfileDisplayDataContextAttribute[] = [],
      confirmedAction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( true ),
      selectedAttribute = this._attribute.getValue(),
      formControls = this._form.controls,
      deletedAttributes = selectedAttribute
        .filter( attribute => !formControls[attribute.attributeId] )
        .map( attribute => attribute.attributeId )
    ;

    if ( deletedAttributes.length > 0 ) {
      confirmedAction.next( false );

      const dialogRef = this.dialog.open( AttributeDeleteConfirmationDialogComponent, {
        data: deletedAttributes
      } );

      dialogRef.afterClosed()
        .subscribe( confirmation => {
          confirmedAction.next( confirmation );
          confirmedAction.complete();
        } );
    }

    return confirmedAction
      .pipe(
        filter( confirmation => confirmation ),
        switchMap( () => {
          Object.entries( formControls )
            .forEach( ( [controlName, control] ) => {
              data.push( {
                attributeId: controlName,
                attributeValue: control.value
              } );
            } );

          return this.apiService.put<IContextAttributeEntryApiResponse>(
            API_ENDPOINT_MAP.attribute.put,
            { attribute: data },
            { uuid: uuid, contextId: contextId }
          )
            .pipe(
              switchMap( ( result: IContextAttributeEntryApiResponse ) =>
              {
                this._attribute.next( result.attribute );
                this._form.markAsPristine();
                return this._attribute;
              } )
            );
        } )
      );
  }

  public delete( uuid: string, contextId: string ): Observable<IProfileDisplayDataContextAttribute[]>
  {
    const confirmedAction: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false ),
      dialogRef = this.dialog.open( ContextDeleteConfirmationDialogComponent )
    ;

    dialogRef.afterClosed()
      .subscribe( confirmation => {
        confirmedAction.next( confirmation );
        confirmedAction.complete();
      } );

    return confirmedAction
      .pipe(
        filter( confirmation => confirmation ),
        switchMap( () => {
          return this.apiService.delete<IContextAttributeEntryApiResponse>(
            API_ENDPOINT_MAP.attribute.delete,
            { uuid: uuid, contextId: contextId }
          )
            .pipe(
              switchMap( ( result: IContextAttributeEntryApiResponse ) => {
                this._attribute.next( null );

                return this._attribute;
              } )
            )
        } )
      );
  }
}
