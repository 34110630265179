import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EOutletNames, EPageNames } from '../interfaces.defs';
import { SearchResultsComponent } from "./results/search-results.component";


const routes: Routes = [
  { path: EPageNames.SEARCH_RESULTS, component: SearchResultsComponent },
  { path: EOutletNames.ACTION_BAR, outlet: EOutletNames.ACTION_BAR,
    loadChildren: () => import('../action-bar/action-bar.module').then( module => module.ActionBarModule )
  },
  { path: '', redirectTo: EPageNames.HOME, pathMatch: 'full' }
];

@NgModule( {
  imports: [ RouterModule.forChild( routes ) ],
  exports: [ RouterModule ],
} )
export class SearchRoutingModule
{
  constructor()
  {}
}
