import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AttributeService } from '../../profile/service/attribute.service';
import { NotificationService } from '../../shared/notification/notification.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';

@Component( {
  selector: 'app-attribute-edit',
  templateUrl: './attribute-edit.component.html',
  styleUrls: [ '../action-bar.components.scss' ]
} )
export class AttributeEditComponent implements OnInit, OnDestroy
{
  private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>( 1 );
  private rootRoute: ActivatedRoute;
  private uuid: string;
  private contextId: string;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    public location: Location,
    public attributeService: AttributeService
  )
  {
  }

  ngOnInit()
  {
    this.rootRoute = this.router.routerState.root.children[0];

    const primaryRoute: ActivatedRoute = this.rootRoute.children.filter( ( route: ActivatedRoute ) => route.outlet === 'primary' )[0];

    this.uuid = primaryRoute.snapshot.paramMap.get( 'uuid' );
    this.contextId = primaryRoute.snapshot.paramMap.get( 'contextId' );
  }

  ngOnDestroy(): void
  {
    this.destroyed.next( true );
    this.destroyed.complete();
  }

  public updateAttribute(): void
  {
    this.attributeService.update( this.uuid, this.contextId )
      .pipe(
        takeUntil( this.destroyed ),
        filter( attribute => attribute !== null)
      )
      .subscribe( () => {
        this.notificationService.showSuccessMessage( 'Attribute successfully updated.' );
      } );
  }

  public addAttribute(): void
  {
    this.attributeService.addAttribute.next( true );
  }

  public resetForm(): void
  {
    this.attributeService.resetForm.next( true );
  }
}
