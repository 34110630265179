import { Injectable } from '@angular/core';
import { ISelectionCollection } from '../interfaces.defs';
import { SelectionModel } from '@angular/cdk/collections';

@Injectable({
  providedIn: 'root'
})
export class SelectionService {

  private _selectionCollection = {};

  constructor() { }

  public setSelectionCollection<T>( key: string ): SelectionModel<T>
  {
    this._selectionCollection[key] = new SelectionModel<T>( true, [] );

    return this._selectionCollection[key];
  }

  public getSelectionCollection<T>( key: string ): SelectionModel<T>
  {
    return this._selectionCollection[key];
  }
}
