import { Component, OnInit } from '@angular/core';

@Component( {
  selector: 'app-context-delete-confirmation',
  templateUrl: './context-delete-confirmation-dialog.component.html',
  styleUrls: [ './context-delete-confirmation-dialog.component.scss' ]
} )
export class ContextDeleteConfirmationDialogComponent
{

  constructor(
  )
  {
  }

}
