<div class="flex-spacer"></div>
<button mat-raised-button color="primary" (click)="preferContactDetail()"
        *ngIf="visibilityService.userManageFunctionalityVisibility | async">
  <mat-icon>star</mat-icon>Mark as Preferred
</button>
<button mat-raised-button color="primary" (click)="resendConfirmationNotification()"
        *ngIf="visibilityService.userManageFunctionalityVisibility | async">
  <mat-icon>loop</mat-icon>Resend confirmation Notification
</button>
<button mat-raised-button color="warn" (click)="deleteContactDetail()"
        *ngIf="visibilityService.userManageFunctionalityVisibility | async">
  <mat-icon>delete</mat-icon>Delete Contact Details
</button>

<app-profile-view></app-profile-view>
