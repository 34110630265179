import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NotificationService } from '../../shared/notification/notification.service';
import { filter, takeUntil } from 'rxjs/operators';
import { ProfileService } from '../../profile/service/profile.service';

@Component( {
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: [ '../action-bar.components.scss' ]
} )
export class ProfileEditComponent implements OnInit
{
  private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>( 1 );
  private rootRoute: ActivatedRoute;
  private uuid: string;

  constructor(
    private router: Router,
    private notificationService: NotificationService,
    public location: Location,
    public profileService: ProfileService
  )
  {
  }

  ngOnInit()
  {
    this.rootRoute = this.router.routerState.root.children[0];

    const primaryRoute: ActivatedRoute = this.rootRoute.children.filter( ( route: ActivatedRoute ) => route.outlet === 'primary' )[0];

    this.uuid = primaryRoute.snapshot.paramMap.get( 'uuid' );
  }

  ngOnDestroy(): void
  {
    this.destroyed.next( true );
    this.destroyed.complete();
  }

  public updateProfile(): void
  {
    this.profileService.update( this.uuid )
      .pipe(
        takeUntil( this.destroyed ),
        filter( profile => profile !== null)
      )
      .subscribe( () => {
        this.notificationService.showSuccessMessage( 'Profile successfully updated.' );
      } );
  }

  public resetForm(): void
  {
    this.profileService.resetForm.next( true );
  }
}
