import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionBarTemplateComponent } from './display/action-bar-template.component';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { SearchResultsSelectionNoneComponent } from './search-results-selection-none/search-results-selection-none.component';
import { SearchResultsSelectionOneComponent } from './search-results-selection-one/search-results-selection-one.component';
import { SearchResultsSelectionMultipleComponent } from './search-results-selection-multiple/search-results-selection-multiple.component';
import { ActionBarRoutingModule } from './action-bar-routing.module';
import { ProfileViewComponent } from './profile-view/profile-view.component';
import { ProfileViewAddressComponent } from './profile-view-address/profile-view-address.component';
import { ProfileViewContractComponent } from './profile-view-contract/profile-view-contract.component';
import { ProfileViewAttributeComponent } from './profile-view-attribute/profile-view-attribute.component';
import { AttributeEditComponent } from './attribute-edit/attribute-edit.component';
import { AttributeAddComponent } from './attribute-add/attribute-add.component';
import { AddressEditComponent } from './address-edit/address-edit.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { AddressAddComponent } from './address-add/address-add.component';
import { ProfileAddMemberComponent } from './member-add/profile-add-member.component';
import { ProfileAddComponent } from './profile-add/profile-add.component';
import { ContactDetailsAddComponent } from './contact-details-add/contact-details-add.component';
import { ProfileViewContactDetailsComponent } from './profile-view-contact-details/profile-view-contact-details.component';
import { ContractAddComponent } from './contract-add/contract-add.component';
import { ContractEditComponent } from './contract-edit/contract-edit.component';
import { BaseComponent } from './base/base.component';
import { EventComponent } from './event/event.component';


@NgModule( {
  declarations: [
    ActionBarTemplateComponent,
    BaseComponent,
    SearchResultsSelectionNoneComponent,
    SearchResultsSelectionOneComponent,
    SearchResultsSelectionMultipleComponent,
    ProfileViewComponent,
    ProfileViewAddressComponent,
    ProfileViewContractComponent,
    ProfileViewAttributeComponent,
    AttributeEditComponent,
    AttributeAddComponent,
    AddressEditComponent,
    ProfileEditComponent,
    AddressAddComponent,
    ProfileAddMemberComponent,
    ProfileAddComponent,
    ContactDetailsAddComponent,
    ProfileViewContactDetailsComponent,
    ContractAddComponent,
    ContractEditComponent,
    EventComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    ActionBarRoutingModule,
  ],
  exports: [
    ActionBarTemplateComponent,
    SearchResultsSelectionNoneComponent,
    SearchResultsSelectionOneComponent,
    SearchResultsSelectionMultipleComponent
  ]
})
export class ActionBarModule { }
