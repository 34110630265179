<div>
  <button mat-icon-button (click)="toggleUserMenuVisibility( $event )">
    <mat-icon>account_circle</mat-icon>
  </button>
</div>
<mat-card @toggleUserMenuVisibility *ngIf="isUserMenuOpen">
  <mat-card-header>
    <mat-card-title>BMW Cockpit Account</mat-card-title>
    <mat-card-subtitle>BMW Cockpit Account specific settings</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-form-field>
      <mat-label>Language</mat-label>
      <mat-select disableOptionCentering>
        <mat-option value="en">English</mat-option>
        <mat-option value="de">German</mat-option>
        <mat-option value="cn">Chinese</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Timezone</mat-label>
      <mat-select disableOptionCentering>
        <mat-option value="local">Local (Europe / Bucharest)</mat-option>
        <mat-option value="utc">UTC</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="logout()">Logout</button>
  </mat-card-actions>
</mat-card>
