import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { from, Observable, of, Subscription } from 'rxjs';
import {
  ICustAuthenticationApiResponse,
  IKeycloakAuthenticationApiResponse, IPermission,
  IValidationApiResponse,
  IValidationResponse
} from '../interfaces.defs';
import { catchError, filter, share, switchMap, take, tap } from 'rxjs/operators';
import { StorageMap } from "@ngx-pwa/local-storage";
import { ContainerStateService } from "../../container-state/service/container-state.service";
import { API_ENDPOINT_MAP, ApiService } from '../../api/api.service';
import { NotificationService } from '../../../shared/notification/notification.service';
import * as IFrameBridge from '../../../../assets/js/iframebridge.min.js';
import { IFrameContainerStrategy } from '../../container-state/interfaces.defs';
import { AuthorizationService } from "../../authorization/authorization.service";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private _isAuthenticated: boolean = false;
  private _logoutEmitter: EventEmitter<boolean> = new EventEmitter();
  private isTokenValidated: boolean = false;

  constructor(
    private localStorage: StorageMap,
    private containerStateService: ContainerStateService,
    private apiService: ApiService,
    private http: HttpClient,
    private notificationService: NotificationService,
    private authorizationService: AuthorizationService
  )
  {
  }

  public authenticateOdmCustAccount( username: string, password: string, scope: string ): Observable<ICustAuthenticationApiResponse>
  {
    const loginUrl = API_ENDPOINT_MAP.authentication._prefix + API_ENDPOINT_MAP.authentication.custLogin,
      httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' })
      },
      body = { custAuthentication: new HttpParams()
          .set( 'scope', scope )
          .set( 'grant_type', 'password' )
          .set( 'username', username )
          .set( 'password', password )
          .toString()
      },
      request = this.apiService.post<ICustAuthenticationApiResponse>(loginUrl, body, null, httpOptions).pipe(
        share()
      )
    ;

    return request.pipe(
      switchMap( ( response: ICustAuthenticationApiResponse ) => {
        this._isAuthenticated = true;
        return this.localStorage.set( this.containerStateService.strategy.localStorageTokenKey, response.custAuthentication.id_token );
      } )
    );
  }

  public authenticateKeycloakAccount( username: string, password: string ): Observable<IKeycloakAuthenticationApiResponse>
  {
    const loginUrl = API_ENDPOINT_MAP.authentication._prefix + API_ENDPOINT_MAP.authentication.keycloakLogin,
          httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json' })
          },
          body = { keycloakAuthentication: new HttpParams()
            .set( 'client_id', 'odm-cockpit' )
            .set( 'grant_type', 'password' )
            .set( 'username', username )
            .set( 'password', password )
            .toString()
          },
          request = this.apiService.post<IKeycloakAuthenticationApiResponse>(loginUrl, body, null, httpOptions).pipe(
            share()
          )
    ;

    return request.pipe(
      switchMap( ( response: IKeycloakAuthenticationApiResponse ) => {
        this._isAuthenticated = true;
        return this.localStorage.set( this.containerStateService.strategy.localStorageTokenKey, response.keycloakAuthentication.access_token );
      } )
    );
  }

  public validate(): Observable<boolean>
  {
    if ( !this._isAuthenticated ) {
      return of( this._isAuthenticated );
    }

    if ( this.isTokenValidated ) {
      return of( this._isAuthenticated );
    }

    const validationUrl = API_ENDPOINT_MAP.authentication._prefix + API_ENDPOINT_MAP.authentication.validate;

    return this.http.get<IValidationApiResponse>( validationUrl )
      .pipe(
        switchMap( ( response: IValidationApiResponse ) => {
          this.isTokenValidated = true;

          if ( response.errors && response.errors.custAuthentication ) {
            this.isTokenValidated = false;

            if ( response.errors.custAuthentication.code === 401 ) {
              this.notificationService.showErrorMessage( {
                errorCode: 401,
                errorMessage: 'Invalid Token. Please login again.'
              } );
            }
          }

          if ( response.custAuthentication && !Object.keys( response.custAuthentication ).length ) {
            this.isTokenValidated = false;

            this.notificationService.showErrorMessage( {
              errorCode: 403,
              errorMessage: 'You do not have the necessary access control permissions to access the application.'
            } );
          }

          this.authorizationService.mapValidationResponseToScopes(response.custAuthentication);

          return of( this.isTokenValidated );
        } )
      );
  }

  public getToken(): Observable<string>
  {
    if ( this.containerStateService.strategy instanceof IFrameContainerStrategy ) {
      return from( <Promise<string>> IFrameBridge.getDataFromParent( 'ODM_COCKPIT_TOKEN' ) )
        .pipe(
          catchError( () => { return of( null ); } ),
          tap( ( IDToken: string ) =>
          {
            if ( IDToken ) {
              this._isAuthenticated = true;
              this.localStorage.set( this.containerStateService.strategy.localStorageTokenKey, IDToken ).subscribe();

              const parentContainerStrategyTokenName = this.containerStateService.getParentStrategy().localStorageTokenKey;

              this.localStorage.has( parentContainerStrategyTokenName )
                .pipe(
                  filter( isLoggedInFromMemberCockpit => isLoggedInFromMemberCockpit )
                )
                .subscribe( () => {
                  this.localStorage.delete( parentContainerStrategyTokenName ).subscribe();
                } );
            }
          } )
        );
    } else {
      return <Observable<string>>this.localStorage.get( this.containerStateService.strategy.localStorageTokenKey )
        .pipe(
          tap( () => {
            const iframeContainerStrategyTokenName = this.containerStateService.getIFrameStrategy().localStorageTokenKey;

            this.localStorage.has( iframeContainerStrategyTokenName )
              .pipe(
                filter( isLoggedInFromODMCockpit => isLoggedInFromODMCockpit )
              )
              .subscribe( () => {
                this.localStorage.delete( iframeContainerStrategyTokenName ).subscribe();
              } );
          } )
        );
    }
  }

  public logout(): void
  {
    this.localStorage.delete( this.containerStateService.strategy.localStorageTokenKey )
      .subscribe( () => {
        this._logoutEmitter.emit( true );
      } );

    this._isAuthenticated = false;
    this.isTokenValidated = false;
  }

  public subscribeToLogoutAction( callback ): Subscription
  {
    return this._logoutEmitter.subscribe( callback );
  }

  public getTokenAndValidate(): Observable<boolean>
  {
    return this.getToken()
      .pipe(
        take( 1 ),
        switchMap( ( IDToken: string ) => {
          if ( IDToken ) {
            this._isAuthenticated = true;
          }

          return this.validate()
            .pipe(
              switchMap( ( isTokenValid: boolean ) => {
                return of( IDToken && isTokenValid );
              } )
            );
        } )
      )
  }
}
