import { Component, OnInit } from '@angular/core';
import { VisibilityService } from '../../core/visibility/service/visibility.service';

@Component( {
  selector: 'action-bar',
  templateUrl: './action-bar-template.component.html',
  styleUrls: [ './action-bar-template.component.scss' ]
} )
export class ActionBarTemplateComponent implements OnInit
{
  constructor(
    public visibilityService: VisibilityService
  )
  {
  }

  ngOnInit()
  {
  }

}
