<app-top-bar></app-top-bar>
<search-filters></search-filters>
<action-bar></action-bar>
<router-outlet></router-outlet>
<ng-container *ngIf="(visibilityService.isSearchView | async) && (visibilityService.singleScopeSelected | async) && (visibilityService.userManageFunctionalityVisibility | async)">
  <button
    mat-fab color="primary"
    cdkDrag
    (cdkDragStarted)="dragStarted()"
    (click)="createProfile()"
  ><mat-icon>add</mat-icon></button>
</ng-container>
