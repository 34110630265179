import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService, API_ENDPOINT_MAP } from 'src/app/core/api/api.service';
import { AbstractFormService } from 'src/app/core/form/abstract-form-service';
import { IProfileSearchApiResponse, IVehicleResponse } from 'src/app/search/interfaces.defs';
import { IP2PTripSummaryApiResponse, ISearchContractApiResponse } from '../interfaces.defs';

@Injectable({
  providedIn: 'root'
})
export class ExportDataService extends AbstractFormService {

  constructor(
    private apiService: ApiService,
  ) {
    super();
  }

  public getVehicles(vinArray: string[]): Observable<IVehicleResponse>[] {
    var tasks$: Observable<IVehicleResponse>[] = [];
    vinArray.forEach((vin) => {
      tasks$.push(
        this.apiService.get<IVehicleResponse>(API_ENDPOINT_MAP.vehicle.get, { vin: vin })
        .pipe(catchError(error => of(error,{"vehicleError":vin})))
      )
    });
    return tasks$;
  }

  public getVehicleTripSummary(vinArray: string[], userIdArray: string[], startDate:string, endDate: string): Observable<IP2PTripSummaryApiResponse> {
    const body = { "p2pTripSummary":{
      "driverIds":userIdArray,
      "endDate": endDate,
      "startDate": startDate,
      "vins": vinArray
    }}
    return this.apiService.post<IP2PTripSummaryApiResponse>(API_ENDPOINT_MAP.vehicleTripSummary.post, body)
  }

  public getContracts(vin: string, scopeArray: string[]): Observable<ISearchContractApiResponse> {

    return this.apiService.get<ISearchContractApiResponse>(API_ENDPOINT_MAP.search.contract.get,
      {
        "scope": scopeArray.join(','),
        "attr.vin": vin,
        "status":"active"
      })
  }

  public getProfiles(uuids: string[], scopeArray: string[]): Observable<IProfileSearchApiResponse> {
    const body = { "search": { "filters": { "scope": scopeArray, "uuid": uuids },"paging":{"limit":uuids.length}}};
    return this.apiService.post<IProfileSearchApiResponse>(API_ENDPOINT_MAP.search.user.post, body)
  }

}
