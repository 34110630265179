import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EPageNames } from "../interfaces.defs";
import { SearchResultsSelectionNoneComponent } from './search-results-selection-none/search-results-selection-none.component';
import { SearchResultsSelectionOneComponent } from './search-results-selection-one/search-results-selection-one.component';
import { SearchResultsSelectionMultipleComponent } from './search-results-selection-multiple/search-results-selection-multiple.component';
import { ProfileViewComponent } from './profile-view/profile-view.component';
import { ProfileViewAddressComponent } from './profile-view-address/profile-view-address.component';
import { ProfileViewContractComponent } from './profile-view-contract/profile-view-contract.component';
import { ProfileViewAttributeComponent } from './profile-view-attribute/profile-view-attribute.component';
import { AttributeEditComponent } from './attribute-edit/attribute-edit.component';
import { AttributeAddComponent } from './attribute-add/attribute-add.component';
import { AddressEditComponent } from './address-edit/address-edit.component';
import { ProfileEditComponent } from './profile-edit/profile-edit.component';
import { AddressAddComponent } from './address-add/address-add.component';
import { ProfileAddMemberComponent } from './member-add/profile-add-member.component';
import { ProfileAddComponent } from './profile-add/profile-add.component';
import { ContactDetailsAddComponent } from './contact-details-add/contact-details-add.component';
import { ProfileViewContactDetailsComponent } from './profile-view-contact-details/profile-view-contact-details.component';
import { ContractAddComponent } from './contract-add/contract-add.component';
import { ContractEditComponent } from './contract-edit/contract-edit.component';
import {EventComponent} from "./event/event.component";


const routes: Routes = [
  { path: EPageNames.ACTION_BAR_SELECTION_NONE, component: SearchResultsSelectionNoneComponent },
  { path: EPageNames.ACTION_BAR_SELECTION_ONE, component: SearchResultsSelectionOneComponent },
  { path: EPageNames.ACTION_BAR_SELECTION_MULTIPLE, component: SearchResultsSelectionMultipleComponent },
  { path: EPageNames.ACTION_BAR_PROFILE_VIEW, component: ProfileViewComponent },
  { path: EPageNames.ACTION_BAR_PROFILE_ADD, component: ProfileAddComponent },
  { path: EPageNames.ACTION_BAR_PROFILE_ADD_MEMBER, component: ProfileAddMemberComponent },
  { path: EPageNames.ACTION_BAR_PROFILE_VIEW_CONTACT_DETAILS, component: ProfileViewContactDetailsComponent },
  { path: EPageNames.ACTION_BAR_PROFILE_VIEW_ADDRESS, component: ProfileViewAddressComponent },
  { path: EPageNames.ACTION_BAR_PROFILE_VIEW_CONTRACT, component: ProfileViewContractComponent },
  { path: EPageNames.ACTION_BAR_PROFILE_VIEW_ATTRIBUTE, component: ProfileViewAttributeComponent },
  { path: EPageNames.ACTION_BAR_CONTACT_DETAILS_ADD, component: ContactDetailsAddComponent },
  { path: EPageNames.ACTION_BAR_ATTRIBUTE_EDIT, component: AttributeEditComponent },
  { path: EPageNames.ACTION_BAR_ATTRIBUTE_ADD, component: AttributeAddComponent },
  { path: EPageNames.ACTION_BAR_ADDRESS_EDIT, component: AddressEditComponent },
  { path: EPageNames.ACTION_BAR_ADDRESS_ADD, component: AddressAddComponent },
  { path: EPageNames.ACTION_BAR_PROFILE_EDIT, component: ProfileEditComponent },
  { path: EPageNames.ACTION_BAR_CONTRACT_ADD, component: ContractAddComponent },
  { path: EPageNames.ACTION_BAR_CONTRACT_EDIT, component: ContractEditComponent },
  { path: EPageNames.ACTION_BAR_EVENT, component: EventComponent },
  { path: '', redirectTo: EPageNames.HOME, pathMatch: 'full' }
];

@NgModule( {
  imports: [ RouterModule.forChild( routes ) ],
  exports: [ RouterModule ],
} )
export class ActionBarRoutingModule
{
  constructor()
  {}
}
