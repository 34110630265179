import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../service/authentication.service';
import { Router } from '@angular/router';
import { EPageNames } from '../../../interfaces.defs';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component( {
  selector: 'authentication-display',
  templateUrl: './authentication-display.component.html',
  styleUrls: ['./authentication-display.component.scss']
} )
export class AuthenticationDisplayComponent implements OnInit
{

  public hidePassword: boolean = true;

  public tabGroupSelectedIndex: number = 0;

  public odmAuthenticationForm: FormGroup = new FormGroup( {
    username: new FormControl( '', Validators.required ),
    password: new FormControl( '', Validators.required ),
    scope: new FormControl( '', Validators.required )
  } );

  public keycloakAuthenticationForm: FormGroup = new FormGroup( {
    username: new FormControl( '', Validators.required ),
    password: new FormControl( '', Validators.required )
  } );

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  )
  {
  }

  ngOnInit()
  {
  }

  public login( accountType: string ): void
  {
    if ( accountType === 'odm' ) {
      this.loginWithOdmCustAccount();
    }

    if ( accountType === 'keycloak' ) {
      this.loginWithKeycloakAccount();
    }
  }

  private loginWithOdmCustAccount(): void
  {
    if ( !this.odmAuthenticationForm.valid ) {
      return;
    }

    const username = this.odmAuthenticationForm.value.username,
      password = this.odmAuthenticationForm.value.password,
      scope = this.odmAuthenticationForm.value.scope
    ;

    this.authenticationService.authenticateOdmCustAccount( username, password, scope )
      .subscribe( () =>
      {
        this.router.navigate( [EPageNames.HOME] );
      } );
  }

  private loginWithKeycloakAccount(): void
  {
    if ( !this.keycloakAuthenticationForm.valid ) {
      return;
    }

    const username = this.keycloakAuthenticationForm.value.username,
      password = this.keycloakAuthenticationForm.value.password
    ;

    this.authenticationService.authenticateKeycloakAccount( username, password )
      .subscribe( () =>
      {
        this.router.navigate( [EPageNames.HOME] );
      } );
  }
}
