import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { EPageNames } from "../../../interfaces.defs";
import { AuthenticationService } from "../service/authentication.service";
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomeRedirectGuard implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  )
  {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    return this.authenticationService.getTokenAndValidate()
      .pipe(
        map( ( isAuthenticated: boolean ) => {
          if ( isAuthenticated ) {
            this.router.navigate( [ EPageNames.HOME ] );
          }

          //we always want to display the login screen
          //unless we're in the case above
          return true;
        } )
      );
  }

}
