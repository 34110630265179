import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IPermission, IValidationResponse } from "../authentication/interfaces.defs";
import { PERMISSIONS } from "../visibility/interfaces.defs";

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  private _permissions: BehaviorSubject<IPermission[]> = new BehaviorSubject<IPermission[]>( null );
  private _currentScopeHasUserManagePermission: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );
  private _currentScopeHasContractManage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );
  private _currentScopeHasContractView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );

  public mapValidationResponseToScopes(custAuthentication: IValidationResponse) {
    const keys: string[] = Object.keys( custAuthentication );
    const permissions: IPermission[] = [];
    for (let key of keys) {
      permissions[key] = custAuthentication[key].references.map( (reference: string) => reference.toLowerCase());
    }
    this._permissions.next(permissions);
  }

  public hasPermission(permissionId: string, scope: string): boolean {
    let permissions: IPermission[] = this._permissions.getValue();
    if ( scope ) {
      scope = scope.toLowerCase();
      scope = scope === 'user_self' ? scope : 'scope_' + scope;
    }
    return permissions && permissions[permissionId] && permissions[permissionId].includes(scope);
  }

  public determineUserManagePermissionForScope(scope: string): void {
    let hasPermission: boolean = this.hasPermission( PERMISSIONS.userManage, scope);
    this._currentScopeHasUserManagePermission.next( hasPermission );
  }

  public determineContractManagePermissionForScope(scope: string): void {
    let hasPermission: boolean = this.hasPermission( PERMISSIONS.contractManage, scope);
    this._currentScopeHasContractManage.next( hasPermission );
  }

  public determineContractViewPermissionForScope(scope: string): void {
    let hasPermission: boolean = this.hasPermission( PERMISSIONS.contractView, scope);
    this._currentScopeHasContractView.next( hasPermission );
  }

  get permissions(): BehaviorSubject<IPermission[]> {
    return this._permissions;
  }

  get currentScopeHasUserManagePermission(): BehaviorSubject<boolean> {
    return this._currentScopeHasUserManagePermission;
  }

  get currentScopeHasContractManage(): BehaviorSubject<boolean> {
    return this._currentScopeHasContractManage;
  }

  get currentScopeHasContractView(): BehaviorSubject<boolean> {
    return this._currentScopeHasContractView;
  }
}
