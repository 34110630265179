import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatSnackBarRef } from "@angular/material/snack-bar";

@Component( {
  selector: 'app-error',
  templateUrl: './error-snack-bar.component.html',
  styleUrls: [ './error-snack-bar.component.scss' ]
} )
export class ErrorSnackBarComponent implements OnInit
{

  public error: { errorMessage: string; errorCode: number | string };
  public timeout: number;

  public progress: number = 0;

  private progressBarTimer: number;

  constructor(
    @Inject( MAT_SNACK_BAR_DATA ) private config: any,
    private snackBarRef: MatSnackBarRef<ErrorSnackBarComponent>,
    private elementRef: ElementRef,
    private renderer: Renderer2
  )
  {
    this.error = config.error;
    this.timeout = config.timeout;
  }

  ngOnInit()
  {
    this.progressBarTimer = setInterval( this.updateProgressBar.bind( this ), this.timeout / 100 );

    this.renderer.listen( this.elementRef.nativeElement.parentNode, 'mouseover', () => {
      clearInterval( this.progressBarTimer );
    });

    this.renderer.listen( this.elementRef.nativeElement.parentNode, 'mouseout', () => {
      this.progressBarTimer = setInterval( this.updateProgressBar.bind( this ), this.timeout / 100 );
    });
  }

  public close(): void
  {
    clearInterval( this.progressBarTimer );
    this.snackBarRef.dismiss();
  }

  private updateProgressBar(): void
  {
    if ( this.progress >= 100 ) {
      clearInterval( this.progressBarTimer );
      this.snackBarRef.dismiss();
    }
    this.progress += 1;
  }

}
