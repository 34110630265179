import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ContractService } from '../../profile/service/contract.service';
import { ContractDefinitionService } from '../../profile/service/contract-definition.service';
import { NotificationService } from '../../shared/notification/notification.service';
import { FormGroup } from '@angular/forms';
import { filter, takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import {
  IProfileDisplayDataContractAttribute,
  IProfileDisplayDataContractDefinitionAttribute, IProfileDisplayDataContractDefinitionPolicy
} from '../../search/interfaces.defs';

@Component( {
  selector: 'app-contract-edit',
  templateUrl: './contract-edit.component.html',
  styleUrls: [ '../action-bar.components.scss' ]
} )
export class ContractEditComponent implements OnInit, OnDestroy
{
  private destroyed: ReplaySubject<boolean> = new ReplaySubject<boolean>( 1 );
  private rootRoute: ActivatedRoute;
  private uuid: string;

  constructor(
    private router: Router,
    private contractDefinitionService: ContractDefinitionService,
    private notificationService: NotificationService,
    public contractService: ContractService,
    public location: Location
  )
  {
  }

  ngOnInit()
  {
    this.rootRoute = this.router.routerState.root.children[0];

    const primaryRoute: ActivatedRoute = this.rootRoute.children.filter( ( route: ActivatedRoute ) => route.outlet === 'primary' )[0];

    this.uuid = primaryRoute.snapshot.paramMap.get( 'uuid' );
  }

  public updateContract(): void
  {
    this.contractService.update( this.uuid )
      .pipe(
        takeUntil( this.destroyed ),
        filter( contract => contract !== null )
      )
      .subscribe( () => {
        this.notificationService.showSuccessMessage( 'Contract successfully updated.' );
      } );
  }

  ngOnDestroy(): void
  {
    this.destroyed.next( true );
    this.destroyed.complete();
  }

  public resetForm(): void
  {
    const form = this.contractService.form,
      contract = this.contractService.contract
    ;

    Object.keys( form.controls )
      .forEach( ( controlName ) => {
        switch ( controlName ) {
          case 'additionalAttributes':
            contract.definition.attributes.forEach( ( attribute: IProfileDisplayDataContractDefinitionAttribute ) => {
              let value = null;
              const contractAttribute = contract.additionalAttributes.find( ( contractAttribute: IProfileDisplayDataContractAttribute ) => {
                return contractAttribute.id === attribute.id;
              } );

              if ( contractAttribute ) {
                value = contractAttribute.value;
              }

              (<FormGroup>form.controls.additionalAttributes).controls[attribute.id].setValue( value );
            } );
            break;

          case 'policyAcceptances':
            contract.definition.policies.forEach( ( policy: IProfileDisplayDataContractDefinitionPolicy ) => {
              let isPolicyAccepted = false;
              const policyControl = (<FormGroup>form.controls.policyAcceptances).get( policy.uuid );

              if ( policyControl && policyControl.value === true ) {
                isPolicyAccepted = true;
              }
              (<FormGroup>form.controls.policyAcceptances).get( policy.uuid).setValue( isPolicyAccepted );
            } );
            break;

          case 'type':
            form.controls.type.setValue( contract.definition.uuid );
            break;

          case 'uuid':
            form.get( 'uuid' ).setValue( contract.uuid );
            break;

          default:
            form.controls[controlName].setValue( contract[controlName] );
            form.controls[controlName].markAsPristine();
            form.controls[controlName].markAsUntouched();
        }
      } );

    form.markAsPristine();
  }
}
