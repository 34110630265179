import { Component } from '@angular/core';
import { VisibilityService } from './core/visibility/service/visibility.service';
import { Router } from '@angular/router';

@Component( {
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.scss' ]
} )
export class AppComponent
{
  public loadingRouteConfig: boolean = false;

  constructor(
    private router: Router,
    private visibilityService: VisibilityService
  )
  {
    this.visibilityService.determineSearchFiltersBarVisibility();
    this.visibilityService.determineActionBarVisibility();
    this.visibilityService.determineIfSearchViewIsVisible();

    //todo: implement proper module loading in interceptor
    this.router.events
      /*.pipe(
        map( ( event: any ) => { console.log(event); return event; } )
      )*/
      .subscribe( event =>
      {
        /*if ( event instanceof RouteConfigLoadStart ) {
          this.loadingRouteConfig = true;
        } else if ( event instanceof RouteConfigLoadEnd ) {
          this.loadingRouteConfig = false;
        } else if ( event instanceof NavigationEnd ) {
          this.loadingRouteConfig = false;
        }*/
      } );
  }
}
