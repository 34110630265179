import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { animate, style, transition, trigger } from '@angular/animations';
import { StorageMap } from '@ngx-pwa/local-storage';
import { AuthenticationService } from '../../core/authentication/service/authentication.service';
import { ISearchCriteria } from '../interfaces.defs';
import { SearchService } from '../service/search.service';

@Component( {
  selector: 'search-display',
  templateUrl: './search-display.component.html',
  styleUrls: [ './search-display.component.scss' ],
  animations: [
    trigger( 'toggleAdvancedSearchVisibility', [
      transition( ':enter', [
        style( { opacity: 0, transform: 'translateY(-100px)' } ),
        animate( '200ms ease-out', style( { opacity: 1, transform: 'translateY(0)' } ) )
      ] ),
      transition( ':leave', animate( '200ms ease-out', style( { opacity: 0, transform: 'translateY(-100px)' } ) ) )
    ] )
  ]
} )
export class SearchDisplayComponent implements OnInit
{
  public searchTextControl: FormControl = new FormControl();
  public advancedFormGroup: FormGroup;
  public isAdvancedSearchOpen: boolean = false;

  @HostListener( 'document:click', [ '$event' ] ) clickOut( event: MouseEvent )
  {
    const searchComponentElement: HTMLElement = this.elementReference.nativeElement,
      dropdownPane: HTMLElement = document.querySelector( '.cdk-overlay-pane' )
    ;

    if (
      !searchComponentElement.contains( <Node> event.target )
      && !dropdownPane
    ) {
      if ( this.isAdvancedSearchOpen ) {
        this.isAdvancedSearchOpen = false;
      }
    }
  }

  constructor(
    private elementReference: ElementRef,
    private localStorage: StorageMap,
    private authenticationService: AuthenticationService,
    public searchService: SearchService
  )
  {
  }

  ngOnInit()
  {
    this.authenticationService.subscribeToLogoutAction( () =>
    {
      this.localStorage.delete( 'isSearchComponentFirstDisplay' ).subscribe();
      this.searchService.removeAllAttributes();
      this.searchService.removeAllContractAttributes();
    } );

    this.advancedFormGroup = new FormGroup( {
      'username': new FormControl(),
      'status': new FormControl(),
      'firstName': new FormControl(),
      'lastName': new FormControl(),
      'contact.address': new FormControl(),
      'address.postal' : new FormControl(),
      'c.contractDefinitionName': new FormControl(),
      'c.status': new FormControl(),
      'additionalAttributes': new FormControl(),
      'contractAttributes': new FormControl()
    } );

    this.searchService.getSearchFilters()
      .subscribe( ( searchCriteria: ISearchCriteria ) =>
      {
        searchCriteria.keywords !== undefined && this.searchTextControl.setValue( searchCriteria.keywords );
        this.advancedFormGroup.patchValue( searchCriteria );
      } );
  }

  public clearSearchValue(): void
  {
    this.searchTextControl.reset();
  }

  public toggleAdvancedSearchVisibility( event: MouseEvent ): void
  {
    this.isAdvancedSearchOpen = !this.isAdvancedSearchOpen;
    this.clearSearchValue();
    event.stopPropagation();
  }

  private search( queryParameters: ISearchCriteria ): void
  {
    this.searchService.setSearchFilters( queryParameters );
  }

  public genericSearch(): void
  {
    this.advancedFormGroup.reset();

    if ( this.searchTextControl.value ) {
      this.search( { keywords: this.searchTextControl.value } );
    } else {
      this.searchService.removeAllFilters();
    }
  }

  public advancedSearch(): void
  {
    this.searchTextControl.reset();
    this.isAdvancedSearchOpen = false;

    const queryParameters: ISearchCriteria = {},
      advancedSearchKeys = Object.keys( this.advancedFormGroup.value )
    ;

    for ( let i = 0, j = advancedSearchKeys.length; i < j; i++ ) {
      if ( this.advancedFormGroup.value[ advancedSearchKeys[ i ] ] ) {
        queryParameters[ advancedSearchKeys[ i ] ] = this.advancedFormGroup.value[ advancedSearchKeys[ i ] ];
      }
    }

    for ( let attribute of this.searchService.attributes ) {
      queryParameters[ attribute.key ] = attribute.value;
    }

    for ( let attribute of this.searchService.contractAttributes ) {
      queryParameters[ attribute.key ] = attribute.value;
    }

    this.search( queryParameters );
  }

}
