import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component( {
  selector: 'app-attribute-delete-confirmation',
  templateUrl: './attribute-delete-confirmation-dialog.component.html',
  styleUrls: [ './attribute-delete-confirmation-dialog.component.scss' ]
} )
export class AttributeDeleteConfirmationDialogComponent
{

  constructor(
    @Inject(MAT_DIALOG_DATA) public deletedAttributes: string[]
  )
  {
  }

}
