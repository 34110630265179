<div class="flex-spacer"></div>
<app-base></app-base>

<div *ngFor="let actionButton of (scopeConfigurationService.currentScopeConfiguration | async)?.custGUI?.profileActionButtons">
  <button mat-raised-button  (click)="scopeConfigurationService.performButtonAction(actionButton)">
    {{scopeConfigurationService.determineButtonName(actionButton)}}
  </button>
</div >

<button mat-raised-button (click)="editProfile()"
        *ngIf="(visibilityService.userManageFunctionalityVisibility | async) && !(visibilityService.isProfileReadOnly | async)">
  <mat-icon>edit</mat-icon>Edit Profile
</button>
<button mat-raised-button (click)="addProfile()"
        *ngIf="(visibilityService.userManageFunctionalityVisibility | async) && profileService.profile && !profileService.profile.member">
  <mat-icon>add</mat-icon>Add Profile
</button>
<button mat-raised-button (click)="refresh()">
  <mat-icon>refresh</mat-icon>Refresh
</button>
<button mat-icon-button [matMenuTriggerFor]="additionalActionsMenu"
        *ngIf="((visibilityService.userManageFunctionalityVisibility | async) || (visibilityService.contractManageFunctionalityVisibility | async))
                && !(visibilityService.isProfileReadOnly | async)">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-label class="large-label">Current Scope: <b>{{currentScope | async}}</b></mat-label>

<mat-menu #additionalActionsMenu="matMenu" >
  <button mat-menu-item (click)="addContactDetails()"
          *ngIf="visibilityService.userManageFunctionalityVisibility | async">
    <mat-icon>add</mat-icon>Add Contact Details
  </button>
  <button mat-menu-item (click)="addAddress()"
          *ngIf="visibilityService.userManageFunctionalityVisibility | async">
    <mat-icon>add</mat-icon>Add Address
  </button>
  <button mat-menu-item (click)="addContract()"
          *ngIf="visibilityService.contractManageFunctionalityVisibility | async">
    <mat-icon>add</mat-icon>Add Contract
  </button>
  <button mat-menu-item (click)="addAttribute()"
          *ngIf="visibilityService.userManageFunctionalityVisibility | async">
    <mat-icon>add</mat-icon>Add Attribute Group
  </button>
</mat-menu>
