import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { NotificationService } from '../../shared/notification/notification.service';
import { filter, take } from 'rxjs/operators';
import { ContractService } from '../../profile/service/contract.service';
import { ContractDefinitionService } from '../../profile/service/contract-definition.service';
import { IProfileDisplayDataContract } from '../../search/interfaces.defs';
import { EPageNames } from '../../interfaces.defs';

@Component( {
  selector: 'app-contract-add',
  templateUrl: './contract-add.component.html',
  styleUrls: [ '../action-bar.components.scss' ]
} )
export class ContractAddComponent implements OnInit
{
  private rootRoute: ActivatedRoute;
  private primaryRoute: ActivatedRoute;
  private uuid: string;

  constructor(
    private router: Router,
    private contractDefinitionService: ContractDefinitionService,
    private notificationService: NotificationService,
    public location: Location,
    public contractService: ContractService
  )
  {
  }

  ngOnInit()
  {
    this.rootRoute = this.router.routerState.root.children[0];

    this.primaryRoute = this.rootRoute.children.filter( ( route: ActivatedRoute ) => route.outlet === 'primary' )[0];

    this.uuid = this.primaryRoute.snapshot.paramMap.get( 'uuid' );
  }

  public createContract(): void
  {
    this.contractService.create( this.uuid )
      .pipe(
        take( 1 ),
        filter( contract => contract !== null )
      )
      .subscribe( ( contract: IProfileDisplayDataContract) => {
        this.notificationService.showSuccessMessage( 'Contract successfully created.' );

        this.router.navigate( [ EPageNames.PROFILE_EDIT_CONTRACT, contract.uuid ], { relativeTo: this.rootRoute } );
      } );
  }

  public resetForm(): void
  {
    this.contractService.resetForm.next( true );
  }
}
