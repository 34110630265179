import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SelectionService } from '../../core/selection/service/selection.service';
import {
  IProfileDisplayDataAddress
} from '../../search/interfaces.defs';
import { ESelectionName } from '../../core/selection/interfaces.defs';
import { EPageNames } from '../../interfaces.defs';
import { ActivatedRoute, Router } from '@angular/router';
import { AddressService } from '../../profile/service/address.service';
import { NotificationService } from '../../shared/notification/notification.service';
import { ProfileService } from '../../profile/service/profile.service';
import { filter, take } from 'rxjs/operators';
import { VisibilityService } from "../../core/visibility/service/visibility.service";


@Component( {
  selector: 'app-profile-view-address',
  templateUrl: './profile-view-address.component.html',
  styleUrls: [ '../action-bar.components.scss' ]
} )
export class ProfileViewAddressComponent implements OnInit
{
  private rootRoute: ActivatedRoute;
  private uuid: string;

  constructor(
    private selectionService: SelectionService,
    private router: Router,
    private addressService: AddressService,
    private notificationService: NotificationService,
    public location: Location,
    public profileService: ProfileService,
    public visibilityService: VisibilityService
  ) {}

  ngOnInit()
  {
    this.rootRoute = this.router.routerState.root.children[0];

    const primaryRoute: ActivatedRoute = this.rootRoute.children.filter( ( route: ActivatedRoute ) => route.outlet === 'primary' )[0];

    this.uuid = primaryRoute.snapshot.paramMap.get( 'uuid' );
  }

  public editAddress()
  {
    const addressSelection = this.selectionService.getSelectionCollection<IProfileDisplayDataAddress>( ESelectionName.CUSTOMER_DISPLAY_DATA_SELECTION ),
      currentAddress = addressSelection.selected[ 0 ]
    ;

    this.router.navigate( [ EPageNames.PROFILE_EDIT_ADDRESS, this.uuid, currentAddress.uuid ], { relativeTo: this.rootRoute } );
  }

  public deleteAddress(): void
  {
    const addressSelection = this.selectionService.getSelectionCollection<IProfileDisplayDataAddress>( ESelectionName.CUSTOMER_DISPLAY_DATA_SELECTION ),
      currentAddress = addressSelection.selected[ 0 ]
    ;

    this.addressService.delete( this.uuid, currentAddress.uuid )
      .pipe(
        take( 1 ),
        filter( address => address === null)
      )
      .subscribe( ( result: IProfileDisplayDataAddress ) => {
        this.notificationService.showSuccessMessage( 'Address successfully deleted.' );
        this.profileService.refresh( this.uuid );
        this.profileService.showProfileButtons();
      } );

  }
}
