import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackBarComponent } from '../snack-bar/error/error-snack-bar.component';
import { SuccessSnackBarComponent } from '../snack-bar/success/success-snack-bar.component';

@Injectable( {
  providedIn: 'root'
} )
export class NotificationService
{
  constructor(
    private snackBar: MatSnackBar
  )
  {
  }

  public showSuccessMessage( message: string ): void
  {
    const snackBarTimeout = 3000;

    this.snackBar.openFromComponent( SuccessSnackBarComponent, {
      data: {
        message: message,
        timeout: snackBarTimeout
      },
      panelClass: [ 'mat-success' ],
      horizontalPosition: 'right',
      verticalPosition: 'bottom'
    } );
  }

  public showErrorMessage( error: { errorMessage: string, errorCode: number | string } ): void
  {
    const snackBarTimeout = 3000;

    this.snackBar.openFromComponent( ErrorSnackBarComponent, {
      data: {
        error: error,
        timeout: snackBarTimeout
      },
      panelClass: [ 'mat-warn' ],
      horizontalPosition: 'right',
      verticalPosition: 'top'
    } );
  }
}
